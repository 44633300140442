// ProjectForm.jsx

import React, { useEffect, useState, useCallback } from 'react';
import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Select,
  Card,
  Spin,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Avatar,
  Switch,
} from 'antd';
import {
  GoogleOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from 'axios';
import GoogleMapsComponent from './GoogleMapsComponent'; // Adjust the import path as needed
import config from './Config';

dayjs.extend(customParseFormat);

const { TextArea } = Input;
const { Option } = Select;
const { Text, Paragraph } = Typography;

const ProjectForm = () => {
  const { projectId } = useParams(); // Extract projectId from URL for editing
  const navigate = useNavigate();
  const [form] = Form.useForm(); // Single form instance
  const [loading, setLoading] = useState(false);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [location, setLocation] = useState(null);

  // States for dropdowns
  const [clients, setClients] = useState([]);
  const [priceLists, setPriceLists] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const baseURL = `${config.apiUrl}`; // Base URL for API endpoints

  // Utility: Get Initials for Avatar
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  };

  // Generic fetch function
  const fetchData = useCallback(
    async (endpoint, setter, errorMsg, postFetch = null) => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}/${endpoint}`);
        setter(response.data);
        if (postFetch) postFetch(response.data);
      } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error);
        message.error(`Failed to load ${errorMsg}`);
      } finally {
        setLoading(false);
      }
    },
    [baseURL]
  );

  // Fetch project details if editing
  const fetchProjectDetails = useCallback(
    async (projectId) => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}/projects/${projectId}`);
        const project = response.data;

        let lat = null,
          lng = null;
        if (project.locationCoordinates) {
          [lat, lng] = project.locationCoordinates
            .split(',')
            .map((coord) => parseFloat(coord.trim()));
        }

        form.setFieldsValue({
          project_name: project.projectName,
          description: project.description,
          start_date: dayjs(project.startDate),
          end_date: dayjs(project.endDate),
          client_id: project.clientId,
          total_area: project.totalArea,
          price_reviewer_id: project.priceReviewerId,
          approver_id: project.approverId,
          price_list_id: project.priceListId,
          currency_id: project.currencyId,
          is_tax_free: project.isTaxFree,
        });

        if (
          project.location &&
          project.locationAddress &&
          lat !== null &&
          lng !== null
        ) {
          const selectedLocation = {
            name: project.location,
            address: project.locationAddress,
            lat,
            lng,
          };
          setLocation(selectedLocation);
          form.setFieldsValue({ location: selectedLocation.name }); // Update form's 'location' field
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          message.error('Project not found');
          navigate('/home'); // Redirect to projects list
        } else {
          console.error('Error fetching project details:', error);
          message.error('Failed to load project details');
        }
      } finally {
        setLoading(false);
      }
    },
    [baseURL, form, navigate]
  );

  // Initialize data on mount
  useEffect(() => {
    fetchData('clients', setClients, 'clients');
    fetchData('pricelists', setPriceLists, 'price lists');
    fetchData('user/reviewers', setReviewers, 'reviewers');
    fetchData('user/approvers', setApprovers, 'approvers');
    fetchData(
      'Currencies/active',
      (data) => {
        setCurrencies(data);
        if (data.length > 0) {
          form.setFieldsValue({ currency_id: data[data.length - 1].currencyId });
        }
      },
      'currencies'
    );

    if (projectId) {
      fetchProjectDetails(projectId);
    } else {
      form.setFieldsValue({
        start_date: dayjs().startOf('day'),
        end_date: dayjs().add(1, 'month').startOf('day'),
      });
    }
  }, [projectId, fetchData, fetchProjectDetails, form]);

  // Handle form submission
  const handleSave = async () => {
    setLoading(true);
    try {
      await form.validateFields(); // Validate all fields
      const values = form.getFieldsValue();

      const projectData = {
        projectId: projectId,
        projectName: values.project_name,
        description: values.description,
        startDate: values.start_date.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: values.end_date.format('YYYY-MM-DDTHH:mm:ss'),
        clientId: values.client_id,
        totalArea: values.total_area,
        priceReviewerId: values.price_reviewer_id,
        approverId: values.approver_id,
        priceListId: values.price_list_id,
        currencyId: values.currency_id,
        isTaxFree: values.is_tax_free,
        location: location ? location.name : '',
        locationAddress: location ? location.address : '',
        locationCoordinates: location
          ? `${location.lat},${location.lng}`
          : '',
      };

      if (projectId) {
        // Update existing project
        await axios.put(`${baseURL}/projects/${projectId}`, projectData);
        message.success('Project updated successfully');
        navigate(`/projects/${projectId}`);
      } else {
        // Create new project
        const response = await axios.post(`${baseURL}/projects`, projectData);
        message.success('Project created successfully');
        navigate(`/projects/${response.data.projectId}`); // Redirect after creation
      }

      // Optionally, handle navigation to other forms or components
      // e.g., navigate(`/projects/${projectId}/costs`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Only handle API errors
        console.error('Error saving project:', error);
        message.error('Failed to save project');
      } else {
        // Validation errors are handled by Ant Design; no need to do anything
        console.error('Validation failed:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle location selection from GoogleMapsComponent
  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
    form.setFieldsValue({ location: selectedLocation.name }); // Update form's 'location' field
  };

  // Disabled dates for end date picker
  const disabledEndDate = (current) => {
    const startDate = form.getFieldValue('start_date');
    return current && current < dayjs(startDate).startOf('day');
  };

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <div>
        <Paragraph>
          <InfoCircleOutlined style={{ marginRight: 8 }} />
          Here you can add the basic information of the project. Please fill in the details
          below.
        </Paragraph>

        <Divider />

        <Form form={form} layout="vertical">
          {/* Project Name */}
          <Form.Item
            name="project_name"
            label="Project Name"
            rules={[{ required: true, message: 'Please enter the project name' }]}
          >
            <Input placeholder="Enter project name" aria-label="Project Name" />
          </Form.Item>

          {/* Description */}
          <Form.Item name="description" label="Description">
            <TextArea rows={4} placeholder="Enter project description" aria-label="Project Description" />
          </Form.Item>

          {/* Start and End Dates */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true, message: 'Please select the start date' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  disabledDate={(current) => current && current < dayjs().startOf('day')}
                  aria-label="Start Date"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="end_date"
                label="End Date"
                dependencies={['start_date']}
                rules={[
                  { required: true, message: 'Please select the end date' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue('start_date');
                      if (!value || !startDate || value.isAfter(startDate)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('End date must be after start date'));
                    },
                  }),
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  disabledDate={disabledEndDate}
                  aria-label="End Date"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Currency and Tax Free */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="currency_id"
                label="Currency"
                rules={[{ required: true, message: 'Please select a currency' }]}
              >
                <Select placeholder="Select a currency" aria-label="Currency">
                  {currencies.map((currency) => (
                    <Option key={currency.currencyId} value={currency.currencyId}>
                      {currency.currencyName} ({currency.symbol})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="is_tax_free"
                valuePropName="checked"
                label="Tax Free"
              >
                <Switch defaultChecked={false} aria-label="Tax Free" />
              </Form.Item>
            </Col>
          </Row>

          {/* Client and Price List */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="client_id"
                label="Client"
                rules={[{ required: true, message: 'Please select a client' }]}
              >
                <Select placeholder="Select a client" aria-label="Client">
                  {clients.map((client) => (
                    <Option key={client.clientId} value={client.clientId}>
                      {client.clientName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="price_list_id" label="Price List">
                <Select placeholder="Select a price list" aria-label="Price List">
                  {priceLists.map((priceList) => (
                    <Option key={priceList.priceListId} value={priceList.priceListId}>
                      {priceList.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Price Reviewer and Approver */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="price_reviewer_id" label="Price Reviewer">
                <Select
                  placeholder="Select a price reviewer"
                  optionLabelProp="label"
                  aria-label="Price Reviewer"
                >
                  {reviewers.map((reviewer) => (
                    <Option key={reviewer.userId} value={reviewer.userId} label={reviewer.firstName}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar size="small" style={{ marginRight: 8 }}>
                          {getInitials(reviewer.firstName, reviewer.lastName)}
                        </Avatar>
                        <span>{`${reviewer.firstName} ${reviewer.lastName}`}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="approver_id" label="Approver">
                <Select
                  placeholder="Select an approver"
                  optionLabelProp="label"
                  aria-label="Approver"
                >
                  {approvers.map((approver) => (
                    <Option key={approver.userId} value={approver.userId} label={approver.firstName}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar size="small" style={{ marginRight: 8 }}>
                          {getInitials(approver.firstName, approver.lastName)}
                        </Avatar>
                        <span>{`${approver.firstName} ${approver.lastName}`}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Total Area and Profit Margin */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="total_area"
                label="Total Area (m²)"
              >
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Enter total area"
                  aria-label="Total Area"
                />
              </Form.Item>
            </Col>
            <Col span={12}>

            </Col>
          </Row>

          {/* Location */}
          <Row gutter={16}>
            <Col span={12}>
              {/* Hidden Form.Item for 'location' */}
              <Form.Item
                name="location"
                rules={[{ required: true, message: 'Please select a location' }]}
              // Instead of hidden, hide the input visually but keep it in the DOM for validation
              >
                <Input style={{ display: 'none' }} />
              </Form.Item>

              {/* Location Selection Button */}
              <Button
                type="primary"
                onClick={() => setLocationModalVisible(true)}
                icon={<GoogleOutlined />}
                aria-label="Select Location"
              >
                {location ? 'Change Location' : 'Select Location'}
              </Button>

              {/* Display Selected Location */}
              {location && (
                <Card
                  style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}
                  >
                    <EnvironmentOutlined
                      style={{ fontSize: '24px', marginRight: '10px' }}
                    />
                    <Text strong>{location.name}</Text>
                  </div>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    {location.address}
                  </Text>
                  <br />
                  {location.lat !== null && location.lng !== null && (
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      Lat: {location.lat}, Lng: {location.lng}
                    </Text>
                  )}
                </Card>
              )}
            </Col>
          </Row>

          {/* Save and Cancel Buttons */}
          <Form.Item>
            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <Button
                type="primary"
                onClick={handleSave}
                icon={<SaveOutlined />}
                loading={loading}
              >
                Save
              </Button>
              <Button
                style={{ marginLeft: '8px' }}
                onClick={() => navigate(projectId ? `/projects/${projectId}` : '/')}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      {/* Location Selection Modal */}
      <GoogleMapsComponent
        visible={locationModalVisible}
        onClose={() => setLocationModalVisible(false)}
        onLocationSelect={handleLocationSelect}
      />
    </>
  );
};

export default ProjectForm;
