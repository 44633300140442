// src/ModuleManager.js
import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Divider, Typography } from 'antd';
import axios from 'axios';
import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, CopyOutlined, BuildOutlined } from '@ant-design/icons';
import config from './Config';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const ModuleManager = () => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/modules/index`);
      setModules(response.data);
    } catch (error) {
      console.error('There was an error fetching the modules!', error);
    } finally {
      setLoading(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleDelete = async (moduleId) => {
    try {
      await axios.delete(`${config.apiUrl}/modules/${moduleId}`);
      message.success('Module deleted successfully');
      fetchModules(); // Refresh the modules list
    } catch (error) {
      console.error('There was an error deleting the module!', error);
      message.error('Failed to delete module');
    }
  };

  const handleEdit = (module) => {
    navigate(`/modules/edit/${module.moduleId}`);
  };

  const handleAdd = () => {
    navigate('/modules/new');
  };

  const handleCopy = (module) => {
    const copiedModule = JSON.parse(JSON.stringify(module));
    copiedModule.moduleName = `${copiedModule.moduleName} - Copy`;
    // Set ids to null and preserve uom for materials
    copiedModule.moduleId = null;
    copiedModule.moduleMaterials = copiedModule.moduleMaterials.map(material => ({
      ...material,
      moduleMaterialId: null,
      moduleId: null,
      uom: material.uom,   // Preserve unit label
    }));
    copiedModule.moduleLabors = copiedModule.moduleLabors.map(labor => ({
      ...labor,
      moduleLaborId: null,
      moduleId: null,
    }));
    copiedModule.moduleExpenses = copiedModule.moduleExpenses.map(expense => ({
      ...expense,
      moduleExpenseId: null,
      moduleId: null,
    }));
    navigate('/modules/new', { state: { module: copiedModule } });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'longDescription',
      key: 'longDescription',
      ...getColumnSearchProps('longDescription'),
    },
    {
      title: 'System',
      dataIndex: 'moduleSystem',
      key: 'moduleSystem',
      responsive: ['md'], // Hide on small screens
      ...getColumnSearchProps('moduleSystem'),
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      responsive: ['md'], // Hide on small screens
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Button type="link" icon={<CopyOutlined />} onClick={() => handleCopy(record)} />
          <Popconfirm
            title="Are you sure you want to delete this module?"
            onConfirm={() => handleDelete(record.moduleId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}><BuildOutlined style={{ marginRight: 8 }} /> Module Inventory</Title>
      <Text>
        Use this page to manage the Modules. You can add, edit, copy, and delete Modules.
      </Text>
      <Divider />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAdd}
        style={{ marginBottom: 16 }}
      >
        Create Module
      </Button>
      <Table
        dataSource={modules}
        columns={columns}
        rowKey="moduleId"
        loading={loading}
      />
    </div>
  );
};

export default ModuleManager;
