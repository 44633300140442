import React, { useEffect } from 'react';
import { Drawer, Form, Button, Input, message, Select } from 'antd'; // Removed Checkbox, added Select
import axios from 'axios';
import config from './Config';

const ExpenseTypeForm = ({ visible, onClose, expenseType, onSave }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (expenseType) {
            // Map expenseType.type to form field 'type'
            form.setFieldsValue(expenseType);
        } else {
            form.resetFields();
        }
    }, [expenseType, form]);

    const handleFinish = async (values) => {
        try {
            const payload = { 
                ...values, 
                ExpenseTypeId: expenseType ? expenseType.expenseTypeId : 0,
                LastModified: new Date().toISOString()
            };

            if (expenseType) {
                await axios.put(`${config.apiUrl}/expenseType/${expenseType.expenseTypeId}`, payload);
                message.success('Expense type updated successfully');
            } else {
                await axios.post(`${config.apiUrl}/expenseType`, payload);
                message.success('Expense type created successfully');
            }
            onSave(); 
            onClose(); 
        } catch (error) {
            console.error('There was an error saving the expense type!', error);
            message.error('Failed to save expense type');
        }
    };

    return (
        <Drawer
            title={expenseType ? 'Edit Expense Type' : 'Create Expense Type'}
            width={720}
            onClose={onClose}
            open={visible}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item
                    name="expenseTypeName"
                    label="Expense Type Name"
                    rules={[{ required: true, message: 'Please enter the expense type name' }]}
                >
                    <Input placeholder="Please enter the expense type name" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter the description' }]}
                >
                    <Input.TextArea placeholder="Please enter the description" rows={4} />
                </Form.Item>

                {/* Replaced Checkbox with Select for 'type' */}
                <Form.Item
                    name="type"
                    label="Expense Type"
                    rules={[{ required: true, message: 'Please select the expense type' }]}
                >
                    <Select placeholder="Select expense type">
                        <Select.Option value="A">Fixed Amount</Select.Option>
                        <Select.Option value="D">Base amount + Distance Rate</Select.Option>
                        <Select.Option value="T">Total Costs %</Select.Option>
                        <Select.Option value="M">Materials %</Select.Option>
                        <Select.Option value="E">Expenses %</Select.Option>
                        <Select.Option value="L">Labor %</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {expenseType ? 'Update' : 'Create'}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ExpenseTypeForm;
