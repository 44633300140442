import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Divider, Row, Col, Select, Tabs, Table, Drawer, Space, message, Avatar, Checkbox } from 'antd';
import { SaveOutlined, EditOutlined, DeleteOutlined, TeamOutlined, PaperClipOutlined, ReconciliationOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from './Config';
import ClientFiles from './ClientFiles';

const { Title } = Typography;

const ClientFormPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clientContacts, setClientContacts] = useState([]);
  const [contactDrawerVisible, setContactDrawerVisible] = useState(false);
  const [contactForm] = Form.useForm();
  const [editingContact, setEditingContact] = useState(null);
  const [clientNameState, setClientNameState] = useState('');
  const [projects, setProjects] = useState([]); // new state for projects

  const { clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientId) {
      setLoading(true);
      axios.get(`${config.apiUrl}/clients/${clientId}`)
        .then(response => {
          form.setFieldsValue(response.data);
          setClientContacts(response.data.clientContacts || []);
          setClientNameState(response.data.clientName || '');
        })
        .catch(error => {
          console.error(error);
          message.error('Failed to load client details');
        })
        .finally(() => setLoading(false));

      // new effect to fetch projects by clientId
      axios.get(`${config.apiUrl}/projects/byclient/${clientId}`)
        .then(response => {
          setProjects(response.data);
        })
        .catch(error => {
          console.error(error);
          message.error('Failed to load projects');
        });
    }
  }, [clientId, form]);

  const onFinish = (values) => {
    setLoading(true);
    // Remove contactId and clientContactId from each contact before sending
    const sanitizedContacts = clientContacts.map(({ contactId, clientContactId, ...rest }) => rest);
    const payload = clientId 
      ? { ...values, clientId, clientContacts: sanitizedContacts }
      : { ...values, clientContacts: sanitizedContacts };
    const request = clientId 
      ? axios.put(`${config.apiUrl}/clients/${clientId}`, payload)
      : axios.post(`${config.apiUrl}/clients`, payload);
    request.then(response => {
      message.success(`Client ${clientId ? 'updated' : 'created'} successfully`);
      navigate('/clients');
    })
    .catch(error => {
      console.error(error);
      message.error('Failed to save client details');
    })
    .finally(() => setLoading(false));
  };

  const openContactDrawer = (contact) => {
    setEditingContact(contact || null);
    if (contact) {
      contactForm.setFieldsValue(contact);
    } else {
      contactForm.resetFields();
    }
    setContactDrawerVisible(true);
  };
  const closeContactDrawer = () => {
    setContactDrawerVisible(false);
    setEditingContact(null);
  };
  const handleContactSave = () => {
    contactForm.validateFields().then(values => {
      if (editingContact) {
        setClientContacts(clientContacts.map(c => c.contactId === editingContact.contactId ? { ...editingContact, ...values } : c));
      } else {
        setClientContacts([...clientContacts, { contactId: Date.now(), ...values }]);
      }
      closeContactDrawer();
    });
  };
  const removeContact = (contactId) => {
    setClientContacts(clientContacts.filter(c => c.contactId !== contactId));
  };

  const contactColumns = [
    {
      title: 'Name',
      render: (_, record) => `${record.firstName || ''} ${record.lastName || ''}`
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: text => text || '-'
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      render: text => text || '-'
    },
    {
      title: 'Action',
      render: (_, record) => (
        <Space>
          <Button type="link" icon={<EditOutlined />} onClick={() => openContactDrawer(record)} />
          <Button type="link" icon={<DeleteOutlined />} onClick={() => removeContact(record.contactId)} />
        </Space>
      )
    }
  ];

  // new project table column definitions
  const projectColumns = [
    {
      title: 'ID',
      dataIndex: 'projectId',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Approved',
      dataIndex: 'approved',
      key: 'approved',
      render: (approved) => <Checkbox checked={approved} disabled />,
    },
    {
      title: 'Modified at',
      dataIndex: 'lastModified',
      key: 'modifiedAt',
      responsive: ['md'],
      render: (text) => text || '-',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="link" icon={<EyeOutlined />} onClick={() => navigate(`/projects/${record.projectId}`)}></Button>
      ),
    },
  ];

  return (
    <>
      {clientId ? (
        <Title level={2}>
          <Avatar style={{ margin: '0 8px' }}>{clientNameState.charAt(0)}</Avatar> {clientNameState}
        </Title>
      ) : (
        <Title level={2}>Create Client</Title>
      )}
      <Divider />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item 
          name="clientName" 
          label="Client Name" 
          rules={[{ required: true, message: 'Please enter the client name' }]}
        >
          <Input placeholder="Client Name" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="legalId" label="Legal ID">
              <Input placeholder="Legal ID" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="clientType" label="Client Type">
              <Select placeholder="Select Client Type">
                <Select.Option value="business">Business</Select.Option>
                <Select.Option value="person">Person</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="websiteUrl" label="Website URL">
              <Input placeholder="Website URL" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="paymentTerms" label="Payment Terms">
              <Input placeholder="Payment Terms" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="billingAddress" label="Billing Address">
              <Input.TextArea placeholder="Billing Address" autoSize={{ minRows: 3 }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="shippingAddress" label="Shipping Address">
              <Input.TextArea placeholder="Shipping Address" autoSize={{ minRows: 3 }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea placeholder="Notes" />
        </Form.Item>
        
        <Tabs
          defaultActiveKey="contacts"
          items={[
            {
              key: "contacts",
              label: <span><TeamOutlined style={{ marginRight: '5px' }} />Contacts</span>,
              children: (
                <>
                  <div style={{ textAlign: 'right', marginBottom: 16 }}>
                    <Button type="primary" onClick={() => openContactDrawer(null)}>Add Contact</Button>
                  </div>
                  <Table 
                    dataSource={clientContacts} 
                    columns={contactColumns} 
                    rowKey="contactId" 
                    pagination={true} 
                    size="small" 
                  />
                </>
              )
            },
            ...(clientId ? [{
              key: "projects",
              label: <span><ReconciliationOutlined style={{ marginRight: '5px' }} />Projects</span>,
              children: (
                <>
                  <div style={{ textAlign: 'right', marginBottom: 16 }}>
                    <Button type="primary" onClick={() => navigate('/projects/create')}>Create</Button>
                  </div>
                  <Table
                    dataSource={projects}
                    columns={projectColumns}
                    rowKey="id"
                    pagination={true}
                    size="small"
                  />
                </>
              )
            }] : []),
            ...(clientId
              ? [{
                  key: "files",
                  label: <span><PaperClipOutlined style={{ marginRight: '5px' }} />Files</span>,
                  children: <ClientFiles clientId={clientId} />
                }]
              : []
            )
          ]}
        />
        
        <Space style={{ float: 'right', marginTop: 16 }}>
          <Button onClick={() => navigate('/clients')}>Back</Button>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </Form>

      <Drawer
        title={editingContact ? 'Edit Contact' : 'Add Contact'}
        placement="right"
        onClose={closeContactDrawer}
        open={contactDrawerVisible}
        width={400}
      >
        <Form form={contactForm} layout="vertical">
          <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'Enter first name' }]}>
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item name="lastName" label="Last Name">
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item name="phone" label="Phone">
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item name="jobTitle" label="Job Title">
            <Input placeholder="Job Title" />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea placeholder="Notes" />
          </Form.Item>
          <Form.Item name="role" label="Role">
            <Input placeholder="Role" />
          </Form.Item>
          <Form.Item 
            name="isPrimary" 
            label="Primary" 
            valuePropName="checked"
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
          <Space style={{ float: 'right' }}>
            <Button onClick={closeContactDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleContactSave}>Save</Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default ClientFormPage;
