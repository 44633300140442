import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Tabs, Table, Space, Checkbox, message, Drawer, Avatar, Row, Col } from 'antd';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import config from './Config';
import { EditOutlined, DeleteOutlined, SaveOutlined, ShopOutlined, BarcodeOutlined, TeamOutlined, PaperClipOutlined } from '@ant-design/icons';
import MaterialForm from './MaterialForm';
import SupplierFiles from './SupplierFiles';

const SupplierPage = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [supplierContacts, setSupplierContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supplierNameState, setSupplierNameState] = useState('');
  const [supplierMaterials, setSupplierMaterials] = useState([]);
  // NEW: State for supplier material form drawer
  const [materialDrawerVisible, setMaterialDrawerVisible] = useState(false);
  const [selectedSupplierMaterial, setSelectedSupplierMaterial] = useState(null);
  // NEW: State for contact drawer and its form
  const [contactDrawerVisible, setContactDrawerVisible] = useState(false);
  const [contactForm] = Form.useForm();
  const [editingContact, setEditingContact] = useState(null);

  // NEW: Open supplier material drawer (for add or edit)
  const openMaterialDrawer = (material) => {
    if (material) {
      setSelectedSupplierMaterial(material);
    } else {
      setSelectedSupplierMaterial(null);
    }
    setMaterialDrawerVisible(true);
  };

  const closeMaterialDrawer = () => {
    setMaterialDrawerVisible(false);
    setSelectedSupplierMaterial(null);
  };

  // NEW: Helper to refresh supplier materials from API
  const refreshSupplierMaterials = () => {
    if (supplierId) {
      axios.get(`${config.apiUrl}/suppliers/${supplierId}`)
        .then(response => {
          setSupplierMaterials(response.data.materials || []);
        })
        .catch(error => console.error('Failed to refresh supplier materials', error));
    }
  };

  // NEW: Handle MaterialForm submission for supplier materials
  const handleMaterialSubmit = (values) => {
    if (selectedSupplierMaterial && selectedSupplierMaterial.materialId) {
      // Existing material; update it
      axios.put(`${config.apiUrl}/materials/${selectedSupplierMaterial.materialId}`, values)
        .then(() => {
          message.success('Material updated successfully');
          refreshSupplierMaterials();
        })
        .catch(error => {
          console.error('Error updating material', error);
          message.error('Failed to update material');
        });
    } else {
      // New material; create it
      axios.post(`${config.apiUrl}/materials`, values)
        .then(() => {
          message.success('Material added successfully');
          refreshSupplierMaterials();
        })
        .catch(error => {
          console.error('Error adding material', error);
          message.error('Failed to add material');
        });
    }
    closeMaterialDrawer();
  };

  // NEW: Delete material function
  const deleteMaterial = (key) => {
    setSupplierMaterials(supplierMaterials.filter(m => m.key !== key));
  };

  // Fetch supplier details if editing
  useEffect(() => {
    if (supplierId) {
      setLoading(true);
      axios.get(`${config.apiUrl}/suppliers/${supplierId}`)
        .then(response => {
          const data = response.data;
          form.setFieldsValue({
            supplierName: data.supplierName,
            contactInfo: data.contactInfo,
            address: data.address,
            websiteUrl: data.websiteUrl,
            paymentTerms: data.paymentTerms,
            bankDetails: data.bankDetails,
            notes: data.notes,
          });
          setSupplierNameState(data.supplierName || 'New Supplier');
          setSupplierContacts(data.supplierContacts || []);
          setSupplierMaterials(data.materials || []);
        })
        .catch(error => {
          console.error(error);
          message.error('Failed to load supplier details');
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [supplierId]);

  // Remove a contact row
  const removeContact = (key) => {
    setSupplierContacts(supplierContacts.filter(c => c.key !== key));
  };

  // Open drawer to add or edit a contact
  const openContactDrawer = (contact) => {
    setEditingContact(contact || null);
    // Populate drawer form if editing an existing contact
    contact ? contactForm.setFieldsValue(contact) : contactForm.resetFields();
    setContactDrawerVisible(true);
  };

  const closeContactDrawer = () => {
    setContactDrawerVisible(false);
    setEditingContact(null);
  };

  // Save contact from drawer form
  const handleContactSave = () => {
    contactForm.validateFields().then(values => {
      // Remove unwanted keys if they exist
      const sanitized = { ...values };
      delete sanitized.contactId;
      delete sanitized.supplierContactId;

      if (editingContact) {
        // Update existing contact
        setSupplierContacts(
          supplierContacts.map(c => c.key === editingContact.key ? { ...c, ...sanitized } : c)
        );
      } else {
        // Add new contact with a new key
        setSupplierContacts([...supplierContacts, { key: Date.now(), ...sanitized }]);
      }
      closeContactDrawer();
    });
  };

  // Save supplier details along with contacts (filter unwanted fields)
  const onFinish = (values) => {
    const sanitizedContacts = supplierContacts.map(({ contactId, supplierContactId, ...rest }) => rest);
    const payload = { ...values, supplierContacts: sanitizedContacts };
    if (supplierId) {
      payload.supplierId = supplierId;
    }
    setLoading(true);
    const request = supplierId
      ? axios.put(`${config.apiUrl}/suppliers/${supplierId}`, payload)
      : axios.post(`${config.apiUrl}/suppliers`, payload);
    request.then((response) => {
      message.success(`Supplier ${supplierId ? 'updated' : 'created'} successfully`);
      // NEW: Navigate to the newly created supplier on POST response
      if (!supplierId && response.data && response.data.supplierId) {
        navigate(`/suppliers/edit/${response.data.supplierId}`);
      }
    })
    .catch(error => {
      console.error(error);
      message.error('Failed to save supplier details');
    })
    .finally(() => setLoading(false));
  };

  // Updated columns: render text and add an Edit button
  const columns = [
    {
      title: 'Name',
      // Using firstName as dataIndex placeholder; render uses both fields
      render: (_, record) => {
        const firstName = record.firstName || '';
        const lastName = record.lastName || '';
        const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
        return (
          <Space>
            <Avatar>{initials}</Avatar>
            {`${firstName} ${lastName}`}
          </Space>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => text || '-'
    },
    {
      title: 'Primary',
      dataIndex: 'isPrimary',
      responsive: ['md'],
      render: (value) => (value ? 'Yes' : 'No')
    },
    {
      title: 'Action',
      render: (text, record) => (
        <Space>
          <Button type="link" icon={<EditOutlined />} onClick={() => openContactDrawer(record)} />
          <Button type="link" icon={<DeleteOutlined />} onClick={() => removeContact(record.key)} />
        </Space>
      )
    }
  ];

  // NEW: Define columns for supplier materials
  const materialColumnsSuppliers = [
    {
      title: 'Material Name',
      dataIndex: 'materialName',
      key: 'materialName',
      render: text => text || '-'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: text => text || '-'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: text => text || '-'
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text, record) => `${record.currencySymbol}${Number(text).toFixed(2)}`
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
      render: text => text || '-'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button type="link" icon={<EditOutlined />} onClick={() => openMaterialDrawer(record)} />
          <Button type="link" icon={<DeleteOutlined />} onClick={() => deleteMaterial(record.key)} />
        </Space>
      )
    }
  ];

  // Before Tabs, build tabs items conditionally
  const tabItems = [
    {
      key: 'supplierContacts',
      label: (
        <span>
          <TeamOutlined style={{ marginRight: '5px' }} /> Contacts
        </span>
      ),
      children: (
        <>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button type="primary" onClick={() => openContactDrawer(null)}>Add Contact</Button>
          </div>
          <Table
            dataSource={supplierContacts}
            columns={columns}
            pagination={false}
            rowKey={record => record.contactId || record.key}
            size="small"
          />
        </>
      )
    },
    {
      key: 'materials',
      label: (
        <span>
          <BarcodeOutlined style={{ marginRight: '5px' }} />
          Materials
        </span>
      ),
      children: (
        <>
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button type="primary" onClick={() => openMaterialDrawer(null)}>Add Material</Button>
          </div>
          <Table
            dataSource={supplierMaterials}
            columns={materialColumnsSuppliers}
            pagination={false}
            rowKey={record => record.materialId || record.key}
            size="small"
          />
        </>
      )
    }
  ];
  
  if (supplierId) { // Only show Files tab in editing mode
    tabItems.push({
      key: 'files',
      label: (
        <span>
          <PaperClipOutlined style={{ marginRight: '5px' }} />
          Files
        </span>
      ),
      children: <SupplierFiles supplierId={supplierId} />
    });
  }

  return (
    <>
      {/* NEW: Supplier name header */}
      <div style={{ margin: '20px', textAlign: 'left' }}>
        <h2 style={{ fontSize: '28px', margin: 0 }}>
          <ShopOutlined style={{ marginRight: '10px' }} />
          {supplierNameState || 'New Supplier'}
        </h2>
      </div>
      {/* Supplier Details Form */}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="supplierName"
          label="Supplier Name"
          rules={[{ required: true, message: 'Please enter the supplier name' }]}
        >
          <Input placeholder="Supplier Name" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contactInfo"
              label="Contact Info"
              rules={[{ required: true, message: 'Please enter the contact info' }]}
            >
              <Input placeholder="Contact Info" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please enter the address' }]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="websiteUrl"
              label="Website URL"
              rules={[{ required: false }]}
            >
              <Input placeholder="Website URL" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="paymentTerms"
              label="Payment Terms"
              rules={[{ required: false }]}
            >
              <Input placeholder="Payment Terms" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="bankDetails"
          label="Bank Details"
          rules={[{ required: false }]}
        >
          <Input placeholder="Bank Details" />
        </Form.Item>
        <Form.Item
          name="notes"
          label="Notes"
          rules={[{ required: false }]}
        >
          <Input.TextArea placeholder="Notes" />
        </Form.Item>

        {/* Tabs for additional details */}
        <Tabs defaultActiveKey="supplierContacts" items={tabItems} />
        <Space style={{ marginTop: 16, float: 'right' }}>
          <Button onClick={() => navigate('/suppliers')}>Back</Button>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </Form>

      {/* NEW: Drawer for adding/editing a contact */}
      <Drawer
        title={editingContact ? 'Edit Contact' : 'Add Contact'}
        placement="right"
        onClose={closeContactDrawer}
        open={contactDrawerVisible}
        width={400}
      >
        <Form form={contactForm} layout="vertical">
          <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'Enter first name' }]}>
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item name="lastName" label="Last Name" >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item name="phone" label="Phone" >
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item name="email" label="Email" >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item name="jobTitle" label="Job Title">
            <Input placeholder="Job Title" />
          </Form.Item>
          <Form.Item name="contactRole" label="Contact Role">
            <Input placeholder="Contact Role" />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea placeholder="Notes" />
          </Form.Item>
          <Form.Item name="isPrimary" label="Primary" valuePropName="checked">
            <Checkbox>Primary</Checkbox>
          </Form.Item>
          <Space style={{ float: 'right' }}>
            <Button onClick={closeContactDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleContactSave}>Save</Button>
          </Space>
        </Form>
      </Drawer>

      {/* NEW: Drawer for supplier material form */}
      <Drawer
        title={selectedSupplierMaterial ? 'Edit Material' : 'Add Material'}
        placement="right"
        onClose={closeMaterialDrawer}
        open={materialDrawerVisible}
        width={400}
      >
        <MaterialForm
          form={null} // Let MaterialForm manage its own form
          initialValues={{ ...(selectedSupplierMaterial || {}), supplierId }}
          onSubmit={handleMaterialSubmit}
          onCancel={closeMaterialDrawer}
          isEditing={!!selectedSupplierMaterial}
        />
      </Drawer>
    </>
  );
};

export default SupplierPage;
