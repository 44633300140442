import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button, Select, Space, InputNumber, TreeSelect, message, Input,Table, Spin, Tabs } from 'antd';
import { MinusCircleOutlined, LoadingOutlined, ToolOutlined, ContainerOutlined, DollarOutlined, PaperClipOutlined, BuildOutlined, SaveOutlined, BarcodeOutlined, TeamOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './Config';
import { useNavigate, useLocation, useParams } from 'react-router-dom'; // Added useParams
import ModuleFiles from './ModuleFiles';

const { Option } = Select;

const ModuleForm = ({ form, onCancel, isEditing, initialValues, fetchModules }) => {
  // Add internal form and use it when no form prop is provided
  const [internalForm] = Form.useForm();
  const effectiveForm = form || internalForm;

  const navigate = useNavigate();
  const location = useLocation();
  const { moduleId: routeModuleId } = useParams();  // Get moduleId from route
  // Use state from navigation if available (for copy)
  initialValues = initialValues || location.state?.module;

  // Declare moduleNameState before using it
  const [moduleNameState, setModuleNameState] = useState('');

  // Determine editing mode either via prop or route parameter
  const editingMode = isEditing || routeModuleId;

  // Compute title: use moduleName if editing and provided, otherwise "New Module"
  const moduleTitle = editingMode ? (moduleNameState || initialValues?.moduleName || "New Module") : "New Module";

  // Define currentModuleId for use in the file tab
  const currentModuleId = initialValues?.moduleId || routeModuleId;

  const [laborOptions, setLaborOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [expenseOptions, setExpenseOptions] = useState([]);
  const [systemOptions, setSystemOptions] = useState([]);
  const [materialUOMs, setMaterialUOMs] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [systemTreeData, setSystemTreeData] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [materialsData, setMaterialsData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);

  const fetchLaborOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/labor`);
      setLaborOptions(response.data);
    } catch (error) {
      console.error('Error fetching labor options:', error);
    }
  }, []);

  const fetchMaterialOptions = useCallback(async () => {
    try {
      const categoriesResponse = await axios.get(`${config.apiUrl}/categories`);
      const materialsResponse = await axios.get(`${config.apiUrl}/materials`);

      const categoryMap = categoriesResponse.data.reduce((acc, category) => {
        acc[category.categoryId] = {
          title: category.categoryName,
          value: `category-${category.categoryId}`,
          key: `category-${category.categoryId}`,
          children: [],
          selectable: false, // Make categories unselectable
        };
        return acc;
      }, {});

      materialsResponse.data.forEach(material => {
        if (categoryMap[material.categoryId]) {
          categoryMap[material.categoryId].children.push({
            title: material.materialName,
            value: material.materialId,
            key: material.materialId,
            uom: material.uom, // Store UOM directly in the material option
            selectable: true, // Make materials selectable
            className: 'product-node', // Add a custom class to product nodes
          });
        }
      });

      setMaterialOptions(Object.values(categoryMap));
    } catch (error) {
      console.error('Error fetching material options:', error);
    }
  }, []);

  const fetchExpenseOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/expense`);
      setExpenseOptions(response.data);
    } catch (error) {
      console.error('Error fetching expense options:', error);
    }
  }, []);

  const fetchSystemOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/system`);
      const systems = response.data;

      const systemMap = systems.reduce((acc, system) => {
        acc[system.systemId] = {
          title: `${system.systemCode} - ${system.description}`,
          value: system.systemId,
          key: system.systemId,
          children: [],
        };
        return acc;
      }, {});

      systems.forEach(system => {
        if (system.parentSystemId && systemMap[system.parentSystemId]) {
          systemMap[system.parentSystemId].children.push(systemMap[system.systemId]);
        }
      });

      const treeData = Object.values(systemMap).filter(system => !systems.find(s => s.systemId === system.value).parentSystemId);
      setSystemTreeData(treeData);
    } catch (error) {
      console.error('Error fetching system options:', error);
    }
  }, []);

  const fetchOptions = useCallback(async () => {
    await Promise.all([fetchLaborOptions(), fetchMaterialOptions(), fetchExpenseOptions(), fetchSystemOptions()]);
  }, [fetchLaborOptions, fetchMaterialOptions, fetchExpenseOptions, fetchSystemOptions]);

  const fetchModuleDetails = useCallback(async (id) => {
    setLoading(true);
    try {
      const [laborResponse, materialsResponse, expensesResponse, moduleResponse] = await Promise.all([
        axios.get(`${config.apiUrl}/moduleslabor/module/${id}`),
        axios.get(`${config.apiUrl}/modulematerials/module/${id}`),
        axios.get(`${config.apiUrl}/moduleexpenses/module/${id}`),
        axios.get(`${config.apiUrl}/modules/${id}`)
      ]);

      effectiveForm.setFieldsValue({
        moduleId: moduleResponse.data.moduleId,
        moduleName: moduleResponse.data.moduleName,
        systemId: moduleResponse.data.systemId,
        description: moduleResponse.data.description,
      });

      // Update state with fetched labor, materials and expenses data
      setLaborData(
        Array.isArray(laborResponse.data)
          ? laborResponse.data.map(l => ({
            key: l.moduleLaborId || Date.now(),
            laborId: l.laborId,
            hoursRequired: l.hoursRequired,
            quantity: l.quantity,
            moduleLaborId: l.moduleLaborId,
          }))
          : []
      );

      setMaterialsData(
        Array.isArray(materialsResponse.data)
          ? materialsResponse.data.map(m => ({
            key: m.moduleMaterialId || Date.now(),
            materialId: m.materialId,
            quantity: m.quantity,
            moduleMaterialId: m.moduleMaterialId,
          }))
          : []
      );

      setExpensesData(
        Array.isArray(expensesResponse.data)
          ? expensesResponse.data.map(e => ({
            key: e.moduleExpenseId || Date.now(),
            expenseId: e.expenseId,
            quantity: e.quantity,
            amount: e.amount,
            moduleExpenseId: e.moduleExpenseId,
          }))
          : []
      );

      // Set UOMs for materials from existing materialOptions
      const newMaterialUOMs = {};
      materialsResponse.data.forEach(material => {
        const materialOption = materialOptions.flatMap(option => option.children).find(opt => opt.value === material.materialId);
        if (materialOption) {
          newMaterialUOMs[material.materialId] = materialOption.uom;
        }
      });
      setMaterialUOMs(newMaterialUOMs);
      setModuleNameState(moduleResponse.data.moduleName);
    } catch (error) {
      console.error('Error fetching module details:', error);
      message.error('Failed to load module details');
    } finally {
      setLoading(false);
    }
  }, [effectiveForm, materialOptions]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  useEffect(() => {
    if (initialValues) {
      // Always use passed initialValues (for copy or edit)
      effectiveForm.setFieldsValue({
        moduleId: initialValues.moduleId || null,
        moduleName: initialValues.moduleName || '',
        systemId: initialValues.systemId || null,
        description: initialValues.description || '',
      });
      setLaborData(initialValues.moduleLabors || []);
      setMaterialsData(initialValues.moduleMaterials || []);
      setExpensesData(initialValues.moduleExpenses || []);
      // NEW: Build initial uom map from moduleMaterials if available
      const initialUOMs = {};
      (initialValues.moduleMaterials || []).forEach(mat => {
        if (mat.materialId && mat.uom) {
          initialUOMs[mat.materialId] = mat.uom;
        }
      });
      setMaterialUOMs(initialUOMs);
    } else {
      effectiveForm.resetFields();
      setLaborData([]);
      setMaterialsData([]);
      setExpensesData([]);
    }
    // If in edit mode (with an id), fetch full details
    if (editingMode && (initialValues?.moduleId || routeModuleId)) {
      const id = initialValues?.moduleId || routeModuleId;
      fetchModuleDetails(id);
    }
  }, [editingMode, initialValues, effectiveForm, fetchModuleDetails, routeModuleId]);

  useEffect(() => {
    // NEW: update uom for any material row missing it
    if (materialOptions.length && materialsData.length) {
      const newUoms = { ...materialUOMs };
      materialsData.forEach(mat => {
        if (mat.materialId && !newUoms[mat.materialId]) {
          const option = materialOptions.flatMap(opt => opt.children).find(opt => opt.value === mat.materialId);
          if (option && option.uom) {
            newUoms[mat.materialId] = option.uom;
          }
        }
      });
      setMaterialUOMs(newUoms);
    }
  }, [materialOptions, materialsData]);

  const handleMaterialChange = useCallback((value, key) => {
    const materialOption = materialOptions.flatMap(option => option.children).find(opt => opt.value === value);
    if (materialOption) {
      const uom = materialOption.uom;
      setMaterialUOMs(prev => ({ ...prev, [value]: uom }));
      // Update the materialsData state directly instead of using form fields
      setMaterialsData(prev => prev.map(row => row.key === key ? { ...row, uom } : row));
    }
  }, [materialOptions]);

  const updateRowValue = (data, setData, key, field, value) => {
    const newData = data.map((row) => (row.key === key ? { ...row, [field]: value } : row));
    setData(newData);
  };

  const laborColumns = [
    {
      title: 'Labor Type',
      dataIndex: 'laborId',
      key: 'laborId',
      render: (text, record) => (
        <Select
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          value={text}
          style={{ width: '100%' }}
          onChange={value => updateRowValue(laborData, setLaborData, record.key, 'laborId', value)}
          placeholder="Select labor elements..."
        >
          {laborOptions.map(labor => (
            <Option key={labor.laborId} value={labor.laborId}>
              {labor.laborType}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={value => updateRowValue(laborData, setLaborData, record.key, 'quantity', value)}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: 'Hours',
      dataIndex: 'hoursRequired',
      key: 'hoursRequired',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={value => updateRowValue(laborData, setLaborData, record.key, 'hoursRequired', value)}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <MinusCircleOutlined
          onClick={() => setLaborData(laborData.filter(row => row.key !== record.key))}
          style={{ fontSize: '12px', cursor: 'pointer' }}
        />
      )
    }
  ];

  const materialColumns = [
    {
      title: 'Material',
      dataIndex: 'materialId',
      key: 'materialId',
      render: (text, record) => (
        <TreeSelect
          style={{ width: '100%' }}
          treeData={materialOptions}
          treeDefaultExpandAll
          showSearch
          value={text}
          onChange={(value) => {
            updateRowValue(materialsData, setMaterialsData, record.key, 'materialId', value);
            handleMaterialChange(value, record.key);
          }}
          placeholder="Select material"
          treeNodeFilterProp="title"
          treeNodeLabelProp="title"
        />
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <Space.Compact style={{ width: '100%' }}>
          <InputNumber
            value={text}
            onChange={value => updateRowValue(materialsData, setMaterialsData, record.key, 'quantity', value)}
            style={{ width: 'calc(100% - 3em)' }}
          />
          <Input
            value={materialUOMs[record.materialId] || ''}
            disabled
            style={{ width: '3em', textAlign: 'center', paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}
          />
        </Space.Compact>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <MinusCircleOutlined
          onClick={() => setMaterialsData(materialsData.filter(row => row.key !== record.key))}
          style={{ fontSize: '12px', cursor: 'pointer' }}
        />
      )
    }
  ];

  const expenseColumns = [
    {
      title: 'Expense',
      dataIndex: 'expenseId',
      key: 'expenseId',
      render: (text, record) => (
        <Select
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          value={text}
          style={{ width: '100%' }}
          onChange={value => updateRowValue(expensesData, setExpensesData, record.key, 'expenseId', value)}
          placeholder="Select expense elements..."
        >
          {expenseOptions.map(expense => (
            <Option key={expense.expenseId} value={expense.expenseId}>
              {expense.expenseName}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={value => updateRowValue(expensesData, setExpensesData, record.key, 'quantity', value)}
          style={{ width: '100%' }} // use full width instead of fixed width
        />
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <MinusCircleOutlined
          onClick={() => setExpensesData(expensesData.filter(row => row.key !== record.key))}
          style={{ fontSize: '12px', cursor: 'pointer' }}
        />
      )
    }
  ];

  const addLaborRow = () => {
    setLaborData([...laborData, { key: Date.now(), laborId: null, quantity: 0, hoursRequired: 0 }]);
  };

  const addMaterialRow = () => {
    setMaterialsData([...materialsData, { key: Date.now(), materialId: null, quantity: 0 }]);
  };

  const addExpenseRow = () => {
    setExpensesData([...expensesData, { key: Date.now(), expenseId: null, quantity: 0 }]);
  };

  const onFinish = async (values) => {
    if (formSubmitted) return;
    setFormSubmitted(true);

    console.log('Form values:', values);

    const { moduleName, systemId, description } = values;
    const currentModuleId = values.moduleId || initialValues?.moduleId || routeModuleId || null;

    let moduleData = {
      // moduleId is included by default
      moduleId: currentModuleId,
      projectId: initialValues?.projectId || 0,
      moduleName,
      moduleSystem: systemOptions.find(system => system.systemId === systemId)?.description || '',
      systemId,
      description,
      total: initialValues?.total || 0,
      moduleMaterials: materialsData,
      moduleLabors: laborData,
      moduleExpenses: expensesData,
    };

    console.log('Prepared module data:', moduleData);

    const isUpdate = Boolean(currentModuleId);
    // Remove moduleId from payload if creating a new module
    if (!isUpdate && moduleData.hasOwnProperty('moduleId')) {
      delete moduleData.moduleId;
      // Remove id fields from arrays so they are not sent to the API:
      moduleData.moduleMaterials = moduleData.moduleMaterials.map(({ moduleMaterialId, moduleId, ...rest }) => rest);
      moduleData.moduleLabors = moduleData.moduleLabors.map(({ moduleLaborId, moduleId, ...rest }) => rest);
      moduleData.moduleExpenses = moduleData.moduleExpenses.map(({ moduleExpenseId, moduleId, ...rest }) => rest);
    }

    try {
      setLoading(true);
      if (isUpdate) {
        await axios.put(`${config.apiUrl}/modules/${currentModuleId}`, moduleData);
        message.success('Module updated successfully');
      } else {
        // Capture the response when creating a new module
        const response = await axios.post(`${config.apiUrl}/modules`, moduleData);
        message.success('Module added successfully');
        // Navigate to the edit mode of the newly created module
        navigate(`/modules/edit/${response.data.moduleId}`);
        return; // Exit on creation; form now will be in edit mode
      }
      if (typeof fetchModules === "function") fetchModules();
      if (typeof onCancel === "function") onCancel();
    } catch (error) {
      console.error('Error saving module:', error);
      message.error('Failed to save module');
    } finally {
      setLoading(false);
      setFormSubmitted(false);
    }
  };

  return (
    <>
      {/* NEW: Header label left aligned showing module name or "New Module" */}
      <div style={{ margin: '20px', textAlign: 'left' }}>
        <h2 style={{ fontSize: '28px', margin: 0 }}>
          <BuildOutlined style={{ marginRight: '10px' }} />
          {moduleTitle}
        </h2>
      </div>
      {loading && (

          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} tip="Loading..."/>
      )}
      <Form
        form={effectiveForm}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          moduleId: initialValues?.moduleId || null,
          moduleName: initialValues?.moduleName || '',
          systemId: initialValues?.systemId || null,
          description: initialValues?.description || '',
        }}
      >
        {isEditing && (
          <Form.Item
            name="moduleId"
            hidden={true}
          >
            <Input type="hidden" />
          </Form.Item>
        )}

        {/* General Module Information Fields */}
        <Form.Item
          name="moduleName"
          label="Module Name"
          rules={[{ required: true, message: 'Please enter the module name' }]}
        >
          <Input placeholder="Please enter the module name" />
        </Form.Item>

        <Form.Item
          name="systemId"
          label="System"
          rules={[{ required: true, message: 'Please select a system' }]}
        >
          <TreeSelect
            placeholder="Select system"
            treeData={systemTreeData}
            treeDefaultExpandAll
            showSearch
            style={{ minWidth: "16em" }}
            filterTreeNode={(input, treeNode) =>
              treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            treeNodeFilterProp="title"
            treeNodeLabelProp="title"
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Please enter the description" />
        </Form.Item>

        {/* Replace Labor, Materials, and Expenses sections with Tabs */}
        <Tabs
          defaultActiveKey="labor"
          items={[
            {
              key: 'labor',
              label: <span><TeamOutlined /> Labor</span>, // replaced ToolOutlined with TeamOutlined
              children: (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
                    <Button type="primary" onClick={addLaborRow}>Add Labor</Button>
                  </div>
                  <Table
                    dataSource={laborData}
                    columns={laborColumns}
                    pagination={false}
                    rowKey="key"
                    size="small"
                  />
                </>
              )
            },
            {
              key: 'materials',
              label: <span><BarcodeOutlined /> Materials</span>, // replaced ContainerOutlined with BarcodeOutlined
              children: (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
                    <Button type="primary" onClick={addMaterialRow}>Add Material</Button>
                  </div>
                  <Table
                    dataSource={materialsData}
                    columns={materialColumns}
                    pagination={false}
                    rowKey="key"
                    size="small"
                  />
                </>
              )
            },
            {
              key: 'expenses',
              label: <span><DollarOutlined /> Expenses</span>,
              children: (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
                    <Button type="primary" onClick={addExpenseRow}>Add Expense</Button>
                  </div>
                  <Table
                    dataSource={expensesData}
                    columns={expenseColumns}
                    pagination={false}
                    rowKey="key"
                    size="small"
                  />
                </>
              )
            },
            // Conditionally add the Files tab only in edit mode (when currentModuleId exists)
            ...(currentModuleId ? [{
              key: 'files',
              label: <span><PaperClipOutlined /> Files</span>,
              children: <ModuleFiles moduleId={currentModuleId} />
            }] : [])
          ]}
        />

        <div style={{ textAlign: 'right', marginTop: 16 }}>
          <Button onClick={() => navigate('/modules')} style={{ marginRight: 8 }}>
            Back
          </Button>
          <Button type="primary" loading={loading} htmlType="submit" icon={<SaveOutlined />}>
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ModuleForm;
