import React, { useState, useEffect } from 'react';
import { Upload, Button, Card, message, List, Popconfirm, Form, Input, Select, Modal, Breadcrumb, Spin, Tree, Checkbox, Row, Col } from 'antd';
import { 
  UploadOutlined, 
  DeleteOutlined, 
  EditOutlined, 
  FolderOutlined, 
  LoadingOutlined, 
  ArrowUpOutlined, 
  UserOutlined, 
  SwapOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import config from './Config';
import { FileIcon, defaultStyles } from 'react-file-icon';

const { Option } = Select;

const ModuleFiles = ({ moduleId }) => {
  const [uploading, setUploading] = useState(false);
  const [folderTree, setFolderTree] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderStack, setFolderStack] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [bulkMoveModalVisible, setBulkMoveModalVisible] = useState(false);
  const [newParentId, setNewParentId] = useState(null);

  // Modal state for create/update folder
  const [folderModalVisible, setFolderModalVisible] = useState(false);
  const [folderModalMode, setFolderModalMode] = useState('create');
  const [folderForm] = Form.useForm();
  const [editingFolder, setEditingFolder] = useState(null);

  // Fetch module folders using moduleId
  const fetchFolders = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/modulefolders/byModule/${moduleId}`);
      setFolderTree(response.data);
    } catch (err) {
      console.error('Error fetching folders:', err);
      message.error('Failed to load module folders.');
    }
  };

  useEffect(() => {
    if (moduleId) {
      fetchFolders();
    }
  }, [moduleId]);

  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop().toLowerCase() : '';
  };

  const deleteFile = async (moduleFileId) => {
    try {
      await axios.delete(`${config.apiUrl}/modulefiles/${moduleFileId}`);
      message.success('File deleted successfully');
      refreshFolders();
    } catch (err) {
      console.error('Delete error:', err);
      message.error('Failed to delete file.');
    }
  };

  // Open modal for creating folder
  const openCreateFolderModal = () => {
    setFolderModalMode('create');
    folderForm.resetFields();
    setFolderModalVisible(true);
  };

  // Open modal for updating folder
  const openUpdateFolderModal = (folder) => {
    setFolderModalMode('update');
    setEditingFolder(folder);
    folderForm.setFieldsValue({
      folderPath: folder.folderPath,
      permissionLevel: folder.permissionLevel.toString()
    });
    setFolderModalVisible(true);
  };

  // Handle modal submit for create/update
  const handleFolderModalSubmit = async () => {
    try {
      const values = await folderForm.validateFields();
      if (folderModalMode === 'create') {
        await axios.post(`${config.apiUrl}/modulefolders`, {
          moduleId,
          folderPath: values.folderPath,
          permissionLevel: parseInt(values.permissionLevel, 10),
          parentFolderId: currentFolder ? currentFolder.moduleFolderId : null
        });
        message.success("Folder created successfully");
      } else {
        await axios.put(`${config.apiUrl}/modulefolders/${editingFolder.moduleFolderId}`, {
          moduleFolderId: editingFolder.moduleFolderId,
          folderPath: values.folderPath,
          permissionLevel: parseInt(values.permissionLevel, 10)
        });
        message.success("Folder renamed successfully");
      }
      setFolderModalVisible(false);
      refreshFolders();
    } catch (err) {
      console.error("Error:", err);
      message.error("Operation failed.");
    }
  };

  const createFolder = () => {
    openCreateFolderModal();
  };

  const updateFolder = (folder) => {
    openUpdateFolderModal(folder);
  };

  const deleteFolder = async (folder) => {
    try {
      await axios.delete(`${config.apiUrl}/modulefolders/${folder.moduleFolderId}`);
      message.success("Folder deleted successfully");
      refreshFolders();
    } catch (err) {
      console.error("Error deleting folder:", err);
      message.error("Failed to delete folder.");
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    try {
      // Request SAS token as before
      const sasResponse = await axios.get(`${config.apiUrl}/sastoken`);
      const { sasToken } = sasResponse.data;
      const sasTokenString = typeof sasToken === 'string' ? sasToken : sasToken.toString();
      // Create blob URL for upload
      const blobUrl = `https://darwinevo.blob.core.windows.net/darwinfs/${file.name}?${sasTokenString}`;
      await axios.put(blobUrl, file, {
        headers: {
          "Content-Type": file.type,
          "x-ms-blob-type": "BlockBlob"
        },
      });
      // Prepare metadata for module file
      const moduleFolderId = currentFolder ? currentFolder.moduleFolderId : null;
      const metadata = {
        moduleFileId: 0,
        moduleId,
        moduleFolderId,
        fileUrl: blobUrl,
        fileName: file.name,
        fileSize: file.size,
        contentType: file.type,
        createdBy: 0,
        createdByName: '',
        createdAt: new Date().toISOString(),
        description: ''
      };
      await axios.post(`${config.apiUrl}/modulefiles`, metadata);
      message.success(`${file.name} uploaded successfully`);
      onSuccess(null, file);
    } catch (err) {
      console.error('Upload error:', err);
      message.error(`Failed to upload ${file.name}`);
      onError(err);
    } finally {
      refreshFolders();
      setUploading(false);
    }
  };

  const handleFileOpen = async (fileUrl) => {
    const win = window.open('about:blank', '_blank');
    if (!win) {
      message.error('Popup blocked. Please allow popups for this site.');
      return;
    }
    try {
      const sasResponse = await axios.get(`${config.apiUrl}/sastoken`);
      const { sasToken } = sasResponse.data;
      const sasTokenString = typeof sasToken === 'string' ? sasToken : sasToken.toString();
      const baseUrl = fileUrl.split('?')[0];
      const newUrl = `${baseUrl}?${sasTokenString}`;
      win.location.href = newUrl;
    } catch (err) {
      console.error('Error fetching SAS token:', err);
      message.error('Failed to open file.');
      win.close();
    }
  };

  const enterFolder = (folder) => {
    setFolderStack(prev => currentFolder ? [...prev, currentFolder] : prev);
    setCurrentFolder(folder);
  };

  const goBack = () => {
    const newStack = [...folderStack];
    const parent = newStack.pop();
    setFolderStack(newStack);
    setCurrentFolder(parent || null);
  };

  const jumpToCrumb = (index) => {
    if (index === -1) {
      setFolderStack([]);
      setCurrentFolder(null);
    } else {
      const newStack = folderStack.slice(0, index + 1);
      setCurrentFolder(newStack[newStack.length - 1]);
      setFolderStack(newStack.slice(0, newStack.length - 1));
    }
  };

  const refreshFolders = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/modulefolders/byModule/${moduleId}`);
      const folders = response.data;
      setFolderTree(folders);
      if (currentFolder) {
        const updated = folders.find(f => f.moduleFolderId === currentFolder.moduleFolderId);
        if (updated) {
          setCurrentFolder(updated);
        }
      }
    } catch (err) {
      console.error("Error refreshing folders:", err);
      message.error("Failed to refresh folders.");
    }
  };

  const handleBulkMove = async () => {
    try {
      await Promise.all(selectedItems.map(item => {
        if (item.type === 'folder') {
          return axios.put(`${config.apiUrl}/modulefolders/${item.moduleFolderId}`, {
            moduleFolderId: item.moduleFolderId,
            folderPath: item.folderPath,
            permissionLevel: item.permissionLevel,
            parentFolderId: newParentId,
          });
        } else {
          return axios.put(`${config.apiUrl}/modulefiles/${item.moduleFileId}`, {
            moduleFileId: item.moduleFileId,
            moduleFolderId: newParentId,
          });
        }
      }));
      message.success("Items moved successfully");
      setBulkMoveModalVisible(false);
      setSelectedItems([]);
      refreshFolders();
    } catch (err) {
      console.error("Bulk move error:", err);
      message.error("Failed to move items.");
    }
  };

  // NEW: Define toggleSelectItem to update selectedItems
  const toggleSelectItem = (item) => {
    setSelectedItems(prev => {
      const exists = prev.find(x => {
        if(item.type === 'folder'){
          return x.type === 'folder' && x.moduleFolderId === item.moduleFolderId;
        } else {
          return x.type === 'file' && x.moduleFileId === item.moduleFileId;
        }
      });
      if (exists) {
        return prev.filter(x => {
          if(item.type === 'folder'){
            return !(x.type === 'folder' && x.moduleFolderId === item.moduleFolderId);
          } else {
            return !(x.type === 'file' && x.moduleFileId === item.moduleFileId);
          }
        });
      } else {
        return [...prev, item];
      }
    });
  };

  // Build treeData for folder navigation
  const buildTreeData = (folders) =>
    folders.filter(folder => folder.moduleFolderId !== 0).map(folder => ({
      title: folder.folderPath,
      key: folder.moduleFolderId.toString(),
      icon: <FolderOutlined />,
      children: folder.childFolders ? buildTreeData(folder.childFolders) : [],
    }));

  const treeData = [
    {
      title: "Files",
      key: "root",
      icon: <FolderOutlined />,
      children: buildTreeData(folderTree.filter(f => f.parentFolderId === null && f.moduleFolderId !== 0))
    }
  ];

  // NEW: Compute displayFolders and displayFiles with ProjectFiles logic
  let displayFolders, displayFiles;
  if (currentFolder) {
    // When a folder is selected, use its children and files
    displayFolders = currentFolder.childFolders || [];
    displayFiles = currentFolder.files || [];
  } else {
    // When no folder is selected, find the root folder (moduleFolderId === 0)
    const rootFolder = folderTree.find(f => f.moduleFolderId === 0);
    displayFiles = rootFolder ? rootFolder.files : [];
    // Also show folders whose parentFolderId is null and moduleFolderId is not 0
    displayFolders = folderTree.filter(f => f.parentFolderId === null && f.moduleFolderId !== 0);
  }
  const combinedItems = [
    ...displayFolders.map(folder => ({ ...folder, type: 'folder' })),
    ...displayFiles.map(file => ({ ...file, type: 'file' }))
  ];

  // NEW: Compute breadcrumb items
  const breadcrumbItems = [
    { title: <a onClick={() => jumpToCrumb(-1)}>Files</a> },
    ...folderStack.map((folder, index) => ({
      title: <a onClick={() => jumpToCrumb(index)}>{folder.folderPath}</a>
    })),
    ...(currentFolder ? [{ title: currentFolder.folderPath }] : [])
  ];

  return (
    <>
      {/* NEW: Header title */}
      {uploading && (
        <div style={{
          position: 'fixed',
          top: 0, left: 0, width: '100%', height: '100%',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          background: 'rgba(255,255,255,0.7)',
          zIndex: 1000
        }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} tip="Uploading file..." fullscreen />
        </div>
      )}
      <Card title={
        <Row justify="space-between" align="middle" style={{ padding: '8px 0', margin: '8px 0' }}>
          <Col xs={24} sm={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {currentFolder && (
                <Button type="primary" icon={<ArrowUpOutlined />} onClick={goBack} style={{ marginRight: 8 }} size="small" />
              )}
              <Breadcrumb items={breadcrumbItems} style={{ marginTop: 8 }} />
            </div>
          </Col>
          <Col xs={24} sm={12} style={{ textAlign: 'right', marginTop: 8 }}>
            <Upload customRequest={customRequest} showUploadList={false} multiple>
              <Button icon={<UploadOutlined />} loading={uploading} style={{ marginRight: 8 }}>Upload</Button>
            </Upload>
            <Button onClick={createFolder} style={{ marginRight: 8 }}>
              <FolderOutlined style={{ marginRight: 4 }} /> New
            </Button>
            <Button onClick={() => { setNewParentId(0); setBulkMoveModalVisible(true); }} disabled={selectedItems.length === 0} type="primary">
              <SwapOutlined style={{ marginRight: 4 }} /> Move
            </Button>
          </Col>
        </Row>
      }>
        {/* NEW: Use combinedItems as the dataSource for the List */}
        <List
          itemLayout="horizontal"
          dataSource={combinedItems}
          renderItem={(item) => {
            if (item.type === 'folder') {
              return (
                <List.Item actions={[
                  <Button type="link" icon={<EditOutlined />} onClick={() => openUpdateFolderModal(item)} />,
                  <Popconfirm title="Delete this folder?" onConfirm={() => deleteFolder(item)} okText="Yes" cancelText="No">
                    <Button type="link" icon={<DeleteOutlined />} />
                  </Popconfirm>
                ]}>
                  <Checkbox
                    checked={
                      !!selectedItems.find(selected =>
                        selected.type === 'folder' && selected.moduleFolderId === item.moduleFolderId
                      )
                    }
                    onChange={() => toggleSelectItem(item)}
                    style={{ marginRight: 8 }}
                  />
                  <List.Item.Meta
                    avatar={<a onClick={() => enterFolder(item)}><FolderOutlined style={{ fontSize: '36px' }} /></a>}
                    title={<a onClick={() => enterFolder(item)}>{item.folderPath}</a>}
                  />
                </List.Item>
              );
            }
            // if file:
            const ext = getFileExtension(item.fileName);
            return (
              <List.Item actions={[
                <Popconfirm
                  title="Are you sure you want to delete this file?"
                  onConfirm={() => deleteFile(item.moduleFileId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              ]}>
                <Checkbox
                  checked={
                    !!selectedItems.find(selected =>
                      selected.type === 'file' && selected.moduleFileId === item.moduleFileId
                    )
                  }
                  onChange={() => toggleSelectItem(item)}
                  style={{ marginRight: 8 }}
                />
                <List.Item.Meta
                  avatar={
                    <a
                      onClick={(e) => { e.preventDefault(); handleFileOpen(item.fileUrl); }}
                      href="#"
                      style={{ marginRight: 8, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <FileIcon extension={ext} {...defaultStyles[ext] || {}} style={{ width: '20px', height: '30px' }} />
                    </a>
                  }
                  title={
                    <a onClick={(e) => { e.preventDefault(); handleFileOpen(item.fileUrl); }} href="#">
                      {item.fileName}
                    </a>
                  }
                  description={
                    <>
                      <UserOutlined style={{ fontSize: 14, marginRight: 4 }} />
                      {item.createdByName} &mdash; {item.fileSize} bytes
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </Card>

      <Modal
        title="Move Selected Items"
        open={bulkMoveModalVisible}
        onOk={handleBulkMove}
        onCancel={() => setBulkMoveModalVisible(false)}
      >
        <Tree
          showIcon
          treeData={treeData}
          onSelect={(selectedKeys) => {
            const key = selectedKeys[0];
            setNewParentId(key === "root" ? null : parseInt(key));
          }}
          selectedKeys={newParentId === null ? ["root"] : [newParentId.toString()]}
          style={{ height: 400, overflowY: 'auto' }}
          size="large"
        />
      </Modal>

      <Modal
        title={folderModalMode === 'create' ? "Create Folder" : "Update Folder"}
        open={folderModalVisible}
        onOk={handleFolderModalSubmit}
        onCancel={() => setFolderModalVisible(false)}
      >
        <Form form={folderForm} layout="vertical">
          <Form.Item label="Folder Name" name="folderPath" rules={[{ required: true, message: 'Please input the folder name' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Permission Level" name="permissionLevel" rules={[{ required: true, message: 'Please select the permission level' }]} initialValue="0">
            <Select>
              <Option value="0">0 - Public</Option>
              <Option value="1">1 - Price Reviewers & Admins</Option>
              <Option value="2">2 - Admins</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModuleFiles;
