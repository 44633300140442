import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, TreeSelect, InputNumber } from 'antd';
import config from './Config';
import axios from 'axios';

const { Option } = Select;

// NEW: add helper to build tree data for categories
const buildTreeData = (categories, parentId = null) => {
  return categories
    .filter((cat) => cat.parentCategoryId === parentId)
    .map((cat) => ({
      title: cat.categoryName,
      value: cat.categoryId,
      key: cat.categoryId,
      children: buildTreeData(categories, cat.categoryId),
    }));
};

const MaterialForm = (props) => {
  const {
    form,
    onSubmit,
    onCancel, // added onCancel to props
    isEditing,
    initialValues,
    categories: propCategories,
    suppliers: propSuppliers,
    taxRates: propTaxRates,
    handlingCosts: propHandlingCosts,
  } = props;
  // Fallback internal form instance if form prop is not provided
  const [internalForm] = Form.useForm();
  const effectiveForm = form || internalForm;
  // Existing currencies state
  const [currencies, setCurrencies] = useState([]);
  // NEW: Local states if props are not provided
  const [categories, setCategories] = useState(propCategories || []);
  const [suppliers, setSuppliers] = useState(propSuppliers || []);
  const [taxRates, setTaxRates] = useState(propTaxRates || []);
  const [handlingCosts, setHandlingCosts] = useState(propHandlingCosts || []);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  // NEW: Fetch missing data similar to MaterialManager
  useEffect(() => {
    if (!propCategories) fetchCategories();
    if (!propSuppliers) fetchSuppliers();
    if (!propTaxRates) fetchTaxRates();
    if (!propHandlingCosts) fetchHandlingCosts();
  }, [propCategories, propSuppliers, propTaxRates, propHandlingCosts]);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/currencies/active`);
      setCurrencies(response.data);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
    }
  };

  // NEW: Helper functions similar to MaterialManager calls
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/categories`);
      // Transform categories into treeData format
      setCategories(buildTreeData(response.data));
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/suppliers`);
      setSuppliers(response.data);
    } catch (error) {
      console.error('Failed to fetch suppliers', error);
    }
  };

  const fetchTaxRates = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/taxrate`);
      setTaxRates(response.data);
    } catch (error) {
      console.error('Failed to fetch tax rates', error);
    }
  };

  const fetchHandlingCosts = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/handlingcost`);
      setHandlingCosts(response.data);
    } catch (error) {
      console.error('Failed to fetch handling costs', error);
    }
  };

  // Wrap the initialization functions in useCallback
  const initializeFormWithValues = React.useCallback(() => {
    if (!initialValues) return;
    const defaultCurrencyId = currencies[currencies.length - 1]?.currencyId;
    const supplier = suppliers.find(s => s.supplierId === initialValues.supplierId);
    effectiveForm.setFieldsValue({
      ...initialValues,
      currencyId: initialValues.currencyId ?? defaultCurrencyId,
      supplierId: supplier
        ? { value: supplier.supplierId, label: supplier.supplierName }
        : undefined,
    });
  }, [initialValues, currencies, suppliers, effectiveForm]);

  const initializeFormWithDefaults = React.useCallback(() => {
    const defaultCurrencyId = currencies[currencies.length - 1]?.currencyId;
    effectiveForm.resetFields();
    effectiveForm.setFieldsValue({
      materialName: '',
      sku: '',
      categoryId: null,
      supplierId: undefined,  // leave undefined if no supplier preselected
      unitPrice: null,
      currencyId: defaultCurrencyId,
      uom: '',
      taxRateId: null,
      handlingCostId: null,
    });
  }, [currencies, effectiveForm]);

  // Update useEffect dependency array to include the memoized callbacks
  useEffect(() => {
    if (currencies.length > 0) {
      if (isEditing && initialValues) {
        initializeFormWithValues();
      } else {
        initializeFormWithDefaults();
      }
    }
  }, [isEditing, initialValues, currencies, initializeFormWithValues, initializeFormWithDefaults]);

  // NEW: update supplier field after suppliers load
  React.useEffect(() => {
    if (suppliers.length > 0 && initialValues && initialValues.supplierId) {
      const sid = Number(initialValues.supplierId);
      const supplier = suppliers.find(s => s.supplierId === sid);
      effectiveForm.setFieldsValue({
        supplierId: supplier
          ? { value: supplier.supplierId, label: supplier.supplierName }
          : initialValues.supplierId,
      });
    }
  }, [suppliers, initialValues, effectiveForm]);

  // NEW: Force supplierId field to be an object with value and label once suppliers load
  React.useEffect(() => {
    if (suppliers.length > 0) {
      const current = effectiveForm.getFieldValue('supplierId');
      // If current value is a primitive (number) then convert it to an object with label
      if (typeof current === 'number' || typeof current === 'string') {
        const sid = Number(current);
        const supplier = suppliers.find(s => s.supplierId === sid);
        if (supplier) {
          effectiveForm.setFieldsValue({
            supplierId: { value: supplier.supplierId, label: supplier.supplierName },
          });
        }
      }
    }
  }, [suppliers, effectiveForm]);

  // NEW: Updated onFinish handler to transform form values
  const handleFinish = (values) => {
    // Convert supplierId from object to number if needed
    if (typeof values.supplierId === 'object' && values.supplierId.value) {
      values.supplierId = values.supplierId.value;
    }

    onSubmit(values);
  };

  const handleCancel = () => {
    effectiveForm.resetFields();
    onCancel();
  };

  return (
    <Form form={effectiveForm} layout="vertical" onFinish={handleFinish}>
      {isEditing && (
        // NEW: Hidden field to include materialId in payload when updating
        <Form.Item name="materialId" hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="materialName"
        label="Material Name"
        rules={[{ required: true, message: 'Please enter the material name' }]}
      >
        <Input placeholder="Please enter the material name" />
      </Form.Item>
      <Form.Item
        name="sku"
        label="SKU"
        rules={[{ required: true, message: 'Please enter the SKU' }]}
      >
        <Input placeholder="Please enter the SKU" />
      </Form.Item>
      <Form.Item
        name="categoryId"
        label="Category"
        rules={[{ required: true, message: 'Please select a category' }]}
      >
        <TreeSelect
          placeholder="Please select a category"
          treeData={categories}
          treeDefaultExpandAll
        />
      </Form.Item>
      <Form.Item
        name="supplierId"
        label="Supplier"
        rules={[{ required: true, message: 'Please select a supplier' }]}
      >
        <Select 
          placeholder="Please select a supplier" 
          labelInValue
          onChange={(value) => {
            // Ensure value is in the proper object shape
            if (value && typeof value === 'object') {
              effectiveForm.setFieldsValue({ supplierId: value });
            } else {
              const supplier = suppliers.find(s => s.supplierId === Number(value));
              effectiveForm.setFieldsValue({
                supplierId: supplier ? { value: supplier.supplierId, label: supplier.supplierName } : value,
              });
            }
          }}
        >
          {suppliers.map((supplier) => (
            <Option key={supplier.supplierId} value={supplier.supplierId}>
              {supplier.supplierName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="unitPrice"
        label="Unit Price"
        rules={[{ required: true, message: 'Please enter the unit price' }]}
      >
        <InputNumber
          min={0}
          placeholder="Please enter the unit price"
          style={{ width: '100%' }}
          addonBefore={
            <Form.Item name="currencyId" noStyle>
              <Select placeholder="Select Currency" style={{ width: 120 }}>
                {currencies.map((currency) => (
                  <Option key={currency.currencyId} value={currency.currencyId}>
                    {currency.symbol} - {currency.currencyCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      <Form.Item
        name="uom"
        label="Unit of Measure"
        rules={[{ required: true, message: 'Please enter the unit of measure' }]}
      >
        <Input placeholder="Please enter the unit of measure" />
      </Form.Item>
      <Form.Item
        name="taxRateId"
        label="Tax Rate"
        rules={[{ required: true, message: 'Please select a tax rate' }]}
      >
        <Select placeholder="Please select a tax rate">
          {taxRates.map((taxRate) => (
            <Option key={taxRate.taxRateId} value={taxRate.taxRateId}>
              {taxRate.longDescription}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="handlingCostId"
        label="Handling Cost"
        rules={[{ required: true, message: 'Please select a handling cost' }]}
      >
        <Select placeholder="Please select a handling cost">
          {handlingCosts.map((handlingCost) => (
            <Option key={handlingCost.handlingCostId} value={handlingCost.handlingCostId}>
              {handlingCost.longDescription}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default MaterialForm;
