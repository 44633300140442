import React, { useState } from 'react';
import { List, Typography, Row, Col, Tooltip, Button, Form, Modal, message, Popconfirm } from 'antd';
import { EnvironmentOutlined, FileTextOutlined, ToolOutlined, DollarOutlined, ShoppingCartOutlined, CopyOutlined, EditOutlined, DeleteOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons'; // Added EyeOutlined
import { useNavigate } from 'react-router-dom';
import EstimationForm from './EstimationForm';
import axios from 'axios';
import config from './Config';

const { Title, Text } = Typography;

const ProjectEstimations = ({ estimations, projectId, fetchProjectDetails }) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedEstimation, setSelectedEstimation] = useState(null);
    const [copyLoading, setCopyLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleAddEstimation = () => {
        setIsEditing(false);
        setSelectedEstimation(null);
        setIsFormVisible(true);
    };

    const handleEditEstimation = (estimation) => {
        setIsEditing(true);
        setSelectedEstimation(estimation);
        setIsFormVisible(true);
    };

    const handleFormSubmit = async (values) => {
        try {
            if (isEditing) {
                await axios.put(`${config.apiUrl}/ProjectEstimations/${selectedEstimation.projectEstimationId}`, {
                    ...values,
                    projectEstimationId: selectedEstimation.projectEstimationId
                });
                message.success('Estimation updated successfully');
            } else {
                await axios.post(`${config.apiUrl}/ProjectEstimations`, { ...values, projectId });
                message.success('Estimation added successfully');
            }
            setIsFormVisible(false);
            fetchProjectDetails();
        } catch (error) {
            console.error('Error saving estimation:', error);
            message.error('Failed to save estimation');
        }
    };

    const handleFormCancel = () => {
        setIsFormVisible(false);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${config.apiUrl}/ProjectEstimations/${id}`);
            message.success('Estimation deleted successfully');
            fetchProjectDetails();
        } catch (error) {
            console.error('Error deleting estimation:', error);
            message.error('Failed to delete estimation');
        }
    };

    const handleCopy = async (id) => {
        setCopyLoading(true);
        try {
            await axios.post(`${config.apiUrl}/EstimationDetails/${id}/duplicate`);
            message.success('Estimation copied successfully');
            fetchProjectDetails();
        } catch (error) {
            console.error('Error copying estimation:', error);
            message.error('Failed to copy estimation');
        } finally {
            setCopyLoading(false);
        }
    };

    const handleViewDetails = (id) => {
        navigate(`/estimation/${id}`);
    };

    const handleViewSummary = (id) => {
        navigate(`/estimation/${id}/summary`); // Navigate to summary page
    };

    return (
        <div style={{ margin: '24px 0' }}>
            <Row justify="end" style={{ marginBottom: '16px' }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={handleAddEstimation}>
                    Add Estimation
                </Button>
            </Row>
            <List
                itemLayout="vertical"
                dataSource={estimations}
                renderItem={item => (
                    <Tooltip
                        title={
                            <div>
                                <div>
                                    <ShoppingCartOutlined /> Materials: {item.symbol} {parseFloat(item.totalMaterials).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                                <div>
                                    <ToolOutlined /> Labor: {item.symbol} {parseFloat(item.totalLabor).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                                <div>
                                    <DollarOutlined /> Expenses: {item.symbol} {parseFloat(item.totalExpenses).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                            </div>
                        }
                        zIndex={1}
                    >
                        <List.Item>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <FileTextOutlined style={{ fontSize: '16px', cursor: 'pointer', marginRight:8 }} onClick={() => handleViewDetails(item.projectEstimationId)} /> 
                                    <Text strong style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() => handleViewDetails(item.projectEstimationId)}>{item.description}</Text>
                                    <div style={{ marginTop: '8px' }}>
                                        <EnvironmentOutlined /> <Text type="secondary">{item.distributionCenterName} ({item.distance} km from Project)</Text>
                                    </div>
                                </Col>
                                <Col span={10}>
                                    <div>
                                        <Text strong>Total:</Text> {item.symbol} {parseFloat(item.totalCost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                    <div>
                                        <Text strong>Profit Margin: </Text>{parseFloat(item.profitMargin).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <Button type="link" icon={<CopyOutlined />} loading={copyLoading} onClick={() => handleCopy(item.projectEstimationId)}></Button>
                                    <Button type="link" icon={<EditOutlined />} onClick={() => handleEditEstimation(item)}></Button>
                                    <Button type="link" icon={<EyeOutlined />} onClick={() => handleViewSummary(item.projectEstimationId)} />
                                    <Popconfirm
                                        title="Are you sure you want to delete this estimation?"
                                        onConfirm={() => handleDelete(item.projectEstimationId)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="link" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </List.Item>
                    </Tooltip>
                )}
            />
            <Modal
                title={isEditing ? "Edit Estimation" : "Create Estimation"}
                visible={isFormVisible}
                onCancel={handleFormCancel}
                footer={null}
            >
                <EstimationForm
                    form={form}
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    isEditing={isEditing}
                    selectedEstimation={selectedEstimation}
                />
            </Modal>
        </div>
    );
};

export default ProjectEstimations;
