import React, { useState, useEffect } from 'react';
import { Upload, Button, Card, message, List, Popconfirm, Form, Input, Select, Modal, Breadcrumb, Spin, Tree, Checkbox, Row, Col } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined, FolderOutlined, LoadingOutlined, ArrowUpOutlined, UserOutlined, SwapOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './Config';
import { FileIcon, defaultStyles } from 'react-file-icon';

// Add helper function to format file sizes
const formatFileSize = (bytes) => {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
    return `${(bytes / 1048576).toFixed(2)} MB`;
};

const { Option } = Select;
const { Dragger } = Upload;

const ProjectFiles = ({ projectId }) => {
    const [uploading, setUploading] = useState(false);
    const [folderTree, setFolderTree] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderStack, setFolderStack] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [bulkMoveModalVisible, setBulkMoveModalVisible] = useState(false);
    const [newParentId, setNewParentId] = useState(null);

    // NEW: Modal state for create/update folder
    const [folderModalVisible, setFolderModalVisible] = useState(false);
    const [folderModalMode, setFolderModalMode] = useState('create'); // 'create' or 'update'
    const [folderForm] = Form.useForm();
    const [editingFolder, setEditingFolder] = useState(null);

    // Fetch folders from the projectfolders endpoint
    const fetchFolders = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/projectfolders/byProject/${projectId}`);
            setFolderTree(response.data);
        } catch (err) {
            console.error('Error fetching folders:', err);
            message.error('Failed to load project folders.');
        }
    };

    useEffect(() => {
        if (projectId) {
            fetchFolders();
        }
    }, [projectId]);

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts.pop().toLowerCase() : '';
    };

    const getInitials = (name) => {
        if (!name) return '';
        const words = name.split(' ');
        return words.map((word) => word.charAt(0).toUpperCase()).join('');
    };

    const deleteFile = async (projectFileId) => {
        try {
            await axios.delete(`${config.apiUrl}/projectfiles/${projectFileId}`);
            message.success('File deleted successfully');
            refreshFolders();
        } catch (err) {
            console.error('Delete error:', err);
            message.error('Failed to delete file.');
        }
    };

    // Remove old window.prompt based functions and add new methods:

    // Open modal for creating folder
    const openCreateFolderModal = () => {
        setFolderModalMode('create');
        folderForm.resetFields();
        setFolderModalVisible(true);
    };

    // Open modal for updating folder
    const openUpdateFolderModal = (folder) => {
        setFolderModalMode('update');
        setEditingFolder(folder);
        folderForm.setFieldsValue({
            folderPath: folder.folderPath,
            permissionLevel: folder.permissionLevel.toString() // Convert to string for proper display
        });
        setFolderModalVisible(true);
    };

    // Handle modal submit for both create and update
    const handleFolderModalSubmit = async () => {
        try {
            const values = await folderForm.validateFields();
            if (folderModalMode === 'create') {
                await axios.post(`${config.apiUrl}/projectfolders`, {
                    projectId,
                    folderPath: values.folderPath,
                    permissionLevel: parseInt(values.permissionLevel, 10),
                    parentFolderId: currentFolder ? currentFolder.projectFolderId : null
                });
                message.success("Folder created successfully");
            } else { // update - include the projectFolderId in payload
                await axios.put(`${config.apiUrl}/projectfolders/${editingFolder.projectFolderId}`, {
                    projectFolderId: editingFolder.projectFolderId,
                    folderPath: values.folderPath,
                    permissionLevel: parseInt(values.permissionLevel, 10)
                });
                message.success("Folder renamed successfully");
            }
            setFolderModalVisible(false);
            refreshFolders();
        } catch (err) {
            console.error("Error:", err);
            message.error("Operation failed.");
        }
    };

    // NEW: Modify createFolder and updateFolder to use modal
    const createFolder = () => {
        openCreateFolderModal();
    };

    const updateFolder = (folder) => {
        openUpdateFolderModal(folder);
    };

    // New function: Delete folder
    const deleteFolder = async (folder) => {
        try {
            await axios.delete(`${config.apiUrl}/projectfolders/${folder.projectFolderId}`);
            message.success("Folder deleted successfully");
            refreshFolders();
        } catch (err) {
            console.error("Error deleting folder:", err);
            message.error("Failed to delete folder.");
        }
    };

    const customRequest = async ({ file, onSuccess, onError }) => {
        setUploading(true);
        try {
            // Step 1: request SAS token
            const sasResponse = await axios.get(`${config.apiUrl}/sastoken`);
            const { sasToken } = sasResponse.data;
            const sasTokenString = typeof sasToken === 'string' ? sasToken : sasToken.toString();

            // Step 2: Create upload URL using the sasToken string 
            const blobUrl = `https://darwinevo.blob.core.windows.net/darwinfs/${file.name}?${sasTokenString}`;

            // Step 3: Upload file to Azure Blob Storage
            await axios.put(blobUrl, file, {
                headers: {
                    "Content-Type": file.type,
                    "x-ms-blob-type": "BlockBlob"
                },
            });

            // Step 4: Prepare metadata object
            const projectFolderId = currentFolder ? currentFolder.projectFolderId : null;
            const metadata = {
                projectFileId: 0,
                projectId,
                projectFolderId,  // Correct projectFolderId used here
                fileUrl: blobUrl,
                fileName: file.name,
                fileSize: file.size,
                contentType: file.type,
                createdBy: 0,
                createdByName: '', // Adjust if needed
                createdAt: new Date().toISOString(),
                description: ''
            };

            // Step 5: Save metadata to Darwin API
            await axios.post(`${config.apiUrl}/projectfiles`, metadata);

            message.success(`${file.name} uploaded successfully`);
            onSuccess(null, file);
        } catch (err) {
            console.error('Upload error:', err);
            message.error(`Failed to upload ${file.name}`);
            onError(err);
        } finally {
            refreshFolders();
            setUploading(false);
        }
    };

    // Updated handler to open a new window immediately and then set location after obtaining SAS token
    const handleFileOpen = async (fileUrl) => {
        // Open blank window immediately on user gesture
        const win = window.open('about:blank', '_blank');
        if (!win) {
            message.error('Popup blocked. Please allow popups for this site.');
            return;
        }
        try {
            const sasResponse = await axios.get(`${config.apiUrl}/sastoken`);
            const { sasToken } = sasResponse.data;
            const sasTokenString = typeof sasToken === 'string' ? sasToken : sasToken.toString();
            const baseUrl = fileUrl.split('?')[0];
            const newUrl = `${baseUrl}?${sasTokenString}`;
            win.location.href = newUrl;
        } catch (err) {
            console.error('Error fetching SAS token:', err);
            message.error('Failed to open file.');
            win.close();
        }
    };

    // Handle folder click: push current folder and set new folder as current
    const enterFolder = (folder) => {
        // Only push currentFolder if it is not null
        setFolderStack(prev => currentFolder ? [...prev, currentFolder] : prev);
        setCurrentFolder(folder);
    };

    // Navigate back
    const goBack = () => {
        const newStack = [...folderStack];
        const parent = newStack.pop();
        setFolderStack(newStack);
        setCurrentFolder(parent || null);
    };

    // NEW: Helper function to navigate using breadcrumbs
    const jumpToCrumb = (index) => {
        if (index === -1) {
            setFolderStack([]);
            setCurrentFolder(null);
        } else {
            const newStack = folderStack.slice(0, index + 1);
            setCurrentFolder(newStack[newStack.length - 1]);
            setFolderStack(newStack.slice(0, newStack.length - 1));
        }
    };

    // NEW: Add helper function to refresh current folder by id
    const refreshCurrentFolder = async (currentFolderId) => {
        try {
            const response = await axios.get(`${config.apiUrl}/projectfolders/${currentFolderId}`);
            // Assume the API returns the updated folder (with childFolders and files)
            const updatedFolder = response.data;
            setCurrentFolder(updatedFolder);
        } catch (err) {
            console.error('Error refreshing folder:', err);
            // If refresh fails, fallback to full refresh
            fetchFolders();
        }
    };

    // NEW: Helper to refresh folders and update currentFolder if applicable
    const refreshFolders = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/projectfolders/byProject/${projectId}`);
            const folders = response.data;
            setFolderTree(folders);
            if (currentFolder) {
                const updated = folders.find(f => f.projectFolderId === currentFolder.projectFolderId);
                if (updated) {
                    setCurrentFolder(updated);
                }
            }
        } catch (err) {
            console.error("Error refreshing folders:", err);
            message.error("Failed to refresh folders.");
        }
    };

    // Handler to toggle selection of an item (file or folder)
    const toggleSelectItem = (item) => {
        setSelectedItems(prev => {
            if (item.type === 'folder') {
                const exists = prev.find(x => x.type === 'folder' && x.projectFolderId === item.projectFolderId);
                if (exists) {
                    return prev.filter(x => !(x.type === 'folder' && x.projectFolderId === item.projectFolderId));
                } else {
                    return [...prev, item];
                }
            } else {
                const exists = prev.find(x => x.type === 'file' && x.projectFileId === item.projectFileId);
                if (exists) {
                    return prev.filter(x => !(x.type === 'file' && x.projectFileId === item.projectFileId));
                } else {
                    return [...prev, item];
                }
            }
        });
    };

    // Build treeData from folderTree (excluding root if needed)
    const buildTreeData = (folders) =>
        folders
            .filter(folder => folder.projectFolderId !== 0) // filter out dummy folder
            .map(folder => ({
                title: folder.folderPath,
                key: folder.projectFolderId.toString(),
                icon: <FolderOutlined />,
                children: folder.childFolders ? buildTreeData(folder.childFolders) : [],
            }));

    // New: Construct treeData with a root "Files" node.
    const treeData = [
        {
            title: "Files",
            key: "root", // unique key for the root node
            icon: <FolderOutlined />,
            children: buildTreeData(folderTree.filter(f => f.parentFolderId === null && f.projectFolderId !== 0))
        }
    ];

    // Handler for bulk move confirmation
    const handleBulkMove = async () => {
        try {
            await Promise.all(selectedItems.map(item => {
                if (item.type === 'folder') {
                    // Update folder by sending its current details with parentFolderId updated
                    return axios.put(`${config.apiUrl}/projectfolders/${item.projectFolderId}`, {
                        projectFolderId: item.projectFolderId,
                        folderPath: item.folderPath,
                        permissionLevel: item.permissionLevel,
                        parentFolderId: newParentId,
                    });
                } else {
                    // Update file by sending the new projectFolderId as the parent
                    return axios.put(`${config.apiUrl}/projectfiles/${item.projectFileId}`, {
                        projectFileId: item.projectFileId,
                        projectFolderId: newParentId,
                    });
                }
            }));
            message.success("Items moved successfully");
            setBulkMoveModalVisible(false);
            setSelectedItems([]);
            refreshFolders();
        } catch (err) {
            console.error("Bulk move error:", err);
            message.error("Failed to move items.");
        }
    };

    // Get list to display: when currentFolder is null use root folders
    let displayFolders, displayFiles;
    if (currentFolder) {
        // Look up the full folder from folderTree to get its childFolders and files
        const folderFromTree = folderTree.find(f => f.projectFolderId === currentFolder.projectFolderId) || currentFolder;
        displayFolders = folderFromTree.childFolders || [];
        displayFiles = folderFromTree.files || [];
    } else {
        const rootFolder = folderTree.find(f => f.projectFolderId === 0);
        displayFiles = rootFolder ? rootFolder.files : [];
        displayFolders = folderTree.filter(f => f.parentFolderId === null && f.projectFolderId !== 0);
    }
    const combinedItems = [
        ...displayFolders.map(folder => ({ ...folder, type: 'folder' })),
        ...displayFiles.map(file => ({ ...file, type: 'file' }))
    ];

    // NEW: Create custom header for the folder/file Card
    const cardHeader = (
        <Row
            justify="space-between"
            align="middle"
            style={{ padding: '8px 0', margin: '8px 0' }} // increased vertical padding and margin
        >
            <Col xs={24} sm={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {currentFolder && (
                        <Button
                            type="primary"
                            icon={<ArrowUpOutlined />}
                            onClick={goBack}
                            style={{ marginRight: 8 }}
                            size='small'
                        />
                    )}
                    <Breadcrumb style={{ marginTop: 8 }}>
                        <Breadcrumb.Item>
                            <a onClick={() => jumpToCrumb(-1)}>Files</a>
                        </Breadcrumb.Item>
                        {folderStack.map((folder, index) => (
                            <Breadcrumb.Item key={folder.projectFolderId}>
                                <a onClick={() => jumpToCrumb(index)}>{folder.folderPath}</a>
                            </Breadcrumb.Item>
                        ))}
                        {currentFolder && (
                            <Breadcrumb.Item>{currentFolder.folderPath}</Breadcrumb.Item>
                        )}
                    </Breadcrumb>
                </div>
            </Col>
            <Col xs={24} sm={12} style={{ textAlign: "right", marginTop: 8 }}>
                <Upload customRequest={customRequest} showUploadList={false} multiple>
                    <Button icon={<UploadOutlined />} loading={uploading} style={{ marginRight: 8 }}>
                        Upload
                    </Button>
                </Upload>
                <Button onClick={createFolder} style={{ marginRight: 8 }}>
                    <FolderOutlined style={{ marginRight: 4 }} />
                    New
                </Button>
                <Button 
                    onClick={() => { 
                        setNewParentId(0); // clear any selection
                        setBulkMoveModalVisible(true); 
                    }} 
                    disabled={selectedItems.length === 0}
                    type='primary'
                >
                    <SwapOutlined style={{ marginRight: 4 }} />
                    Move
                </Button>
            </Col>
        </Row>
    );

    return (
        <>
            {uploading && (
                <div style={{
                    position: 'fixed',
                    top: 0, left: 0, width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    background: 'rgba(255,255,255,0.7)',
                    zIndex: 1000
                }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} tip="Uploading file..." fullscreen />
                </div>
            )}
            <Card title={cardHeader}> 
                <List
                    itemLayout="horizontal"
                    dataSource={combinedItems}
                    renderItem={(item) => {
                        if (item.type === 'folder') {
                            return (
                                <List.Item actions={[
                                    <Button
                                        type="link"
                                        icon={<EditOutlined />}
                                        onClick={() => updateFolder(item)}
                                    />,
                                    <Popconfirm
                                        title="Delete this folder?"
                                        onConfirm={() => deleteFolder(item)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="link" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                ]}>
                                    <Checkbox
                                        checked={
                                            !!selectedItems.find(selected =>
                                                selected.type === item.type &&
                                                (item.type === 'folder'
                                                    ? selected.projectFolderId === item.projectFolderId
                                                    : selected.projectFileId === item.projectFileId)
                                            )
                                        }
                                        onChange={() => toggleSelectItem(item)}
                                        style={{ marginRight: 8 }}
                                    />
                                    <List.Item.Meta
                                        avatar={
                                            <a onClick={() => enterFolder(item)}>
                                                <FolderOutlined style={{ fontSize: '36px' }} />
                                            </a>
                                        }
                                        title={
                                            <a onClick={() => enterFolder(item)}>{item.folderPath}</a>
                                        }
                                        description={
                                            <>
                                                <UserOutlined style={{ fontSize: 14, marginRight: 4 }} />
                                                {item.createdByName}
                                            </>
                                        }
                                    />
                                </List.Item>
                            );
                        }
                        // if it is a file
                        const ext = getFileExtension(item.fileName);
                        return (
                            <List.Item actions={[
                                <Popconfirm
                                    title="Are you sure you want to delete this file?"
                                    onConfirm={() => deleteFile(item.projectFileId)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            ]}>
                                <Checkbox
                                    checked={
                                        !!selectedItems.find(selected =>
                                            selected.type === item.type &&
                                            (item.type === 'folder'
                                                ? selected.projectFolderId === item.projectFolderId
                                                : selected.projectFileId === item.projectFileId)
                                        )
                                    }
                                    onChange={() => toggleSelectItem(item)}
                                    style={{ marginRight: 8 }}
                                />
                                <List.Item.Meta
                                    avatar={
                                        <a
                                            onClick={(e) => { e.preventDefault(); handleFileOpen(item.fileUrl); }}
                                            href="#"
                                            style={{ marginRight: 8, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <FileIcon extension={ext} {...defaultStyles[ext] || {}} style={{ width: '20px', height: '30px' }} />
                                        </a>
                                    }
                                    title={
                                        <a
                                            onClick={(e) => { e.preventDefault(); handleFileOpen(item.fileUrl); }}
                                            href="#"
                                        >
                                            {item.fileName}
                                        </a>
                                    }
                                    description={
                                        <>
                                            <UserOutlined style={{ fontSize: 14, marginRight: 4 }} />
                                            {item.createdByName} &mdash; {formatFileSize(item.fileSize)}
                                        </>
                                    }
                                />
                            </List.Item>
                        );
                    }}
                />
            </Card>

            {/* Bulk Move Modal */}
            <Modal
                title="Move Selected Items"
                visible={bulkMoveModalVisible}
                onOk={handleBulkMove}
                onCancel={() => setBulkMoveModalVisible(false)}
            >
                <Tree
                    showIcon
                    treeData={treeData}
                    onSelect={(selectedKeys) => {
                        const key = selectedKeys[0];
                        setNewParentId(key === "root" ? null : parseInt(key));
                    }}
                    selectedKeys={newParentId === null ? ["root"] : [newParentId.toString()]}
                    style={{ height: 400, overflowY: 'auto' }} // taller tree styling
                    size="large"
                />
            </Modal>

            {/* NEW: Folder Modal */}
            <Modal
                title={folderModalMode === 'create' ? "Create Folder" : "Update Folder"}
                visible={folderModalVisible}
                onOk={handleFolderModalSubmit}
                onCancel={() => setFolderModalVisible(false)}
            >
                <Form form={folderForm} layout="vertical">
                    <Form.Item
                        label="Folder Name"
                        name="folderPath"
                        rules={[{ required: true, message: 'Please input the folder name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Permission Level"
                        name="permissionLevel"
                        rules={[{ required: true, message: 'Please select the permission level' }]}
                        initialValue="0"
                    >
                        <Select>
                            <Option value="0">0 - Public</Option>
                            <Option value="1">1 - Price Reviewers & Admins</Option>
                            <Option value="2">2 - Admins</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ProjectFiles;
