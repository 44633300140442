import React, { useState, useEffect } from 'react';
import { Upload, Button, Card, message, List, Popconfirm, Form, Input, Select, Modal, Breadcrumb, Spin, Tree, Checkbox, Row, Col } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined, FolderOutlined, LoadingOutlined, ArrowUpOutlined, UserOutlined, SwapOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './Config';
import { FileIcon, defaultStyles } from 'react-file-icon';

const { Option } = Select;
const { Dragger } = Upload;

const ClientFiles = ({ clientId }) => {
    // ...existing code for states...
    const [uploading, setUploading] = useState(false);
    const [folderTree, setFolderTree] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderStack, setFolderStack] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [bulkMoveModalVisible, setBulkMoveModalVisible] = useState(false);
    const [newParentId, setNewParentId] = useState(null);

    // Modal state for create/update folder
    const [folderModalVisible, setFolderModalVisible] = useState(false);
    const [folderModalMode, setFolderModalMode] = useState('create');
    const [folderForm] = Form.useForm();
    const [editingFolder, setEditingFolder] = useState(null);

    // Helper: format file size
    const formatFileSize = (bytes) => {
        if (bytes < 1024) return `${bytes} B`;
        if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
        return `${(bytes / 1048576).toFixed(2)} MB`;
    };

    // Fetch client folders
    const fetchFolders = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/clientfolders/byClient/${clientId}`);
            setFolderTree(response.data);
        } catch (err) {
            console.error('Error fetching client folders:', err);
            message.error('Failed to load client folders.');
        }
    };

    useEffect(() => {
        if (clientId) {
            fetchFolders();
        }
    }, [clientId]);

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts.pop().toLowerCase() : '';
    };

    // Delete client file
    const deleteFile = async (clientFileId) => {
        try {
            await axios.delete(`${config.apiUrl}/clientfiles/${clientFileId}`);
            message.success('File deleted successfully');
            refreshFolders();
        } catch (err) {
            console.error('Delete error:', err);
            message.error('Failed to delete file.');
        }
    };

    // Open modal for creating folder
    const openCreateFolderModal = () => {
        setFolderModalMode('create');
        folderForm.resetFields();
        setFolderModalVisible(true);
    };

    // Open modal for updating folder
    const openUpdateFolderModal = (folder) => {
        setFolderModalMode('update');
        setEditingFolder(folder);
        folderForm.setFieldsValue({
            folderPath: folder.folderPath,
            permissionLevel: folder.permissionLevel.toString()
        });
        setFolderModalVisible(true);
    };

    // Handle modal submit for create/update folder
    const handleFolderModalSubmit = async () => {
        try {
            const values = await folderForm.validateFields();
            if (folderModalMode === 'create') {
                await axios.post(`${config.apiUrl}/clientfolders`, {
                    clientId,
                    folderPath: values.folderPath,
                    permissionLevel: parseInt(values.permissionLevel, 10),
                    parentFolderId: currentFolder ? currentFolder.clientFolderId : null
                });
                message.success("Folder created successfully");
            } else {
                await axios.put(`${config.apiUrl}/clientfolders/${editingFolder.clientFolderId}`, {
                    clientFolderId: editingFolder.clientFolderId,
                    folderPath: values.folderPath,
                    permissionLevel: parseInt(values.permissionLevel, 10)
                });
                message.success("Folder renamed successfully");
            }
            setFolderModalVisible(false);
            refreshFolders();
        } catch (err) {
            console.error("Error:", err);
            message.error("Operation failed.");
        }
    };

    const createFolder = () => {
        openCreateFolderModal();
    };

    const updateFolder = (folder) => {
        openUpdateFolderModal(folder);
    };

    // Delete folder
    const deleteFolder = async (folder) => {
        try {
            await axios.delete(`${config.apiUrl}/clientfolders/${folder.clientFolderId}`);
            message.success("Folder deleted successfully");
            refreshFolders();
        } catch (err) {
            console.error("Error deleting folder:", err);
            message.error("Failed to delete folder.");
        }
    };

    // Custom upload request
    const customRequest = async ({ file, onSuccess, onError }) => {
        setUploading(true);
        try {
            const sasResponse = await axios.get(`${config.apiUrl}/sastoken`);
            const { sasToken } = sasResponse.data;
            const sasTokenString = typeof sasToken === 'string' ? sasToken : sasToken.toString();
            const blobUrl = `https://darwinevo.blob.core.windows.net/darwinfs/${file.name}?${sasTokenString}`;
            await axios.put(blobUrl, file, {
                headers: {
                    "Content-Type": file.type,
                    "x-ms-blob-type": "BlockBlob"
                },
            });
            const clientFolderId = currentFolder ? currentFolder.clientFolderId : null;
            const metadata = {
                clientFileId: 0,
                clientId,
                clientFolderId,
                fileUrl: blobUrl,
                fileName: file.name,
                fileSize: file.size,
                contentType: file.type,
                createdBy: 0,
                createdByName: '',
                createdAt: new Date().toISOString(),
                description: ''
            };
            await axios.post(`${config.apiUrl}/clientfiles`, metadata);
            message.success(`${file.name} uploaded successfully`);
            onSuccess(null, file);
        } catch (err) {
            console.error('Upload error:', err);
            message.error(`Failed to upload ${file.name}`);
            onError(err);
        } finally {
            refreshFolders();
            setUploading(false);
        }
    };

    // Handle file open with SAS token
    const handleFileOpen = async (fileUrl) => {
        const win = window.open('about:blank', '_blank');
        if (!win) {
            message.error('Popup blocked. Please allow popups for this site.');
            return;
        }
        try {
            const sasResponse = await axios.get(`${config.apiUrl}/sastoken`);
            const { sasToken } = sasResponse.data;
            const sasTokenString = typeof sasToken === 'string' ? sasToken : sasToken.toString();
            const baseUrl = fileUrl.split('?')[0];
            const newUrl = `${baseUrl}?${sasTokenString}`;
            win.location.href = newUrl;
        } catch (err) {
            console.error('Error fetching SAS token:', err);
            message.error('Failed to open file.');
            win.close();
        }
    };

    // Folder navigation handlers
    const enterFolder = (folder) => {
        setFolderStack(prev => currentFolder ? [...prev, currentFolder] : prev);
        setCurrentFolder(folder);
    };

    const goBack = () => {
        const newStack = [...folderStack];
        const parent = newStack.pop();
        setFolderStack(newStack);
        setCurrentFolder(parent || null);
    };

    const jumpToCrumb = (index) => {
        if (index === -1) {
            setFolderStack([]);
            setCurrentFolder(null);
        } else {
            const newStack = folderStack.slice(0, index + 1);
            setCurrentFolder(newStack[newStack.length - 1]);
            setFolderStack(newStack.slice(0, newStack.length - 1));
        }
    };

    // Refresh folders
    const refreshFolders = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/clientfolders/byClient/${clientId}`);
            const folders = response.data;
            setFolderTree(folders);
            if (currentFolder) {
                const updated = folders.find(f => f.clientFolderId === currentFolder.clientFolderId);
                if (updated) {
                    setCurrentFolder(updated);
                }
            }
        } catch (err) {
            console.error("Error refreshing folders:", err);
            message.error("Failed to refresh folders.");
        }
    };

    // Toggle selection of an item
    const toggleSelectItem = (item) => {
        setSelectedItems(prev => {
            if (item.type === 'folder') {
                const exists = prev.find(x => x.type === 'folder' && x.clientFolderId === item.clientFolderId);
                return exists ? prev.filter(x => x.clientFolderId !== item.clientFolderId) : [...prev, item];
            } else {
                const exists = prev.find(x => x.type === 'file' && x.clientFileId === item.clientFileId);
                return exists ? prev.filter(x => x.clientFileId !== item.clientFileId) : [...prev, item];
            }
        });
    };

    // Build tree data for bulk move
    const buildTreeData = (folders) =>
        folders.filter(folder => folder.clientFolderId !== 0).map(folder => ({
            title: folder.folderPath,
            key: folder.clientFolderId.toString(),
            icon: <FolderOutlined />,
            children: folder.childFolders ? buildTreeData(folder.childFolders) : [],
        }));
    
    const treeData = [
        {
            title: "Files",
            key: "root",
            icon: <FolderOutlined />,
            children: buildTreeData(folderTree.filter(f => f.parentFolderId === null && f.clientFolderId !== 0))
        }
    ];

    // Bulk move handler
    const handleBulkMove = async () => {
        try {
            await Promise.all(selectedItems.map(item => {
                if (item.type === 'folder') {
                    return axios.put(`${config.apiUrl}/clientfolders/${item.clientFolderId}`, {
                        clientFolderId: item.clientFolderId,
                        folderPath: item.folderPath,
                        permissionLevel: item.permissionLevel,
                        parentFolderId: newParentId,
                    });
                } else {
                    // Updated: include required fields in the payload
                    return axios.put(`${config.apiUrl}/clientfiles/${item.clientFileId}`, {
                        clientFileId: item.clientFileId,
                        clientFolderId: newParentId,
                        clientId, // include clientId if needed
                        fileUrl: item.fileUrl,
                        fileName: item.fileName,
                        contentType: item.contentType,
                    });
                }
            }));
            message.success("Items moved successfully");
            setBulkMoveModalVisible(false);
            setSelectedItems([]);
            refreshFolders();
        } catch (err) {
            console.error("Bulk move error:", err);
            message.error("Failed to move items.");
        }
    };

    // Determine items to display
    let displayFolders, displayFiles;
    if (currentFolder) {
        const folderFromTree = folderTree.find(f => f.clientFolderId === currentFolder.clientFolderId) || currentFolder;
        displayFolders = folderFromTree.childFolders || [];
        displayFiles = folderFromTree.files || [];
    } else {
        const rootFolder = folderTree.find(f => f.clientFolderId === 0);
        displayFiles = rootFolder ? rootFolder.files : [];
        displayFolders = folderTree.filter(f => f.parentFolderId === null && f.clientFolderId !== 0);
    }
    const combinedItems = [
        ...displayFolders.map(folder => ({ ...folder, type: 'folder' })),
        ...displayFiles.map(file => ({ ...file, type: 'file' }))
    ];

    // Header with breadcrumbs and action buttons
    const breadcrumbItems = [
        { title: <a onClick={() => jumpToCrumb(-1)}>Files</a> },
        ...folderStack.map((folder, index) => ({ title: <a onClick={() => jumpToCrumb(index)}>{folder.folderPath}</a> })),
        ...(currentFolder ? [{ title: currentFolder.folderPath }] : [])
    ];
    const cardHeader = (
        <Row justify="space-between" align="middle" style={{ padding: '8px 0', margin: '8px 0' }}>
            <Col xs={24} sm={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {currentFolder && (
                        <Button type="primary" icon={<ArrowUpOutlined />}
                            onClick={goBack} style={{ marginRight: 8 }} size="small" />
                    )}
                    <Breadcrumb items={breadcrumbItems} style={{ marginTop: 8 }} />
                </div>
            </Col>
            <Col xs={24} sm={12} style={{ textAlign: "right", marginTop: 8 }}>
                <Upload customRequest={customRequest} showUploadList={false} multiple>
                    <Button icon={<UploadOutlined />} loading={uploading} style={{ marginRight: 8 }}>
                        Upload
                    </Button>
                </Upload>
                <Button onClick={createFolder} style={{ marginRight: 8 }}>
                    <FolderOutlined style={{ marginRight: 4 }} /> New
                </Button>
                <Button onClick={() => { setNewParentId(0); setBulkMoveModalVisible(true); }}
                    disabled={selectedItems.length === 0} type="primary">
                    <SwapOutlined style={{ marginRight: 4 }} /> Move
                </Button>
            </Col>
        </Row>
    );

    return (
        <>
            {uploading && (
                <div style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    background: 'rgba(255,255,255,0.7)', zIndex: 1000
                }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} tip="Uploading file..." />
                </div>
            )}
            <Card title={cardHeader}>
                <List
                    itemLayout="horizontal"
                    dataSource={combinedItems}
                    renderItem={(item) => {
                        if (item.type === 'folder') {
                            return (
                                <List.Item actions={[
                                    <Button type="link" icon={<EditOutlined />} onClick={() => updateFolder(item)} />,
                                    <Popconfirm title="Delete this folder?" onConfirm={() => deleteFolder(item)}
                                        okText="Yes" cancelText="No">
                                        <Button type="link" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                ]}>
                                    <Checkbox
                                        checked={!!selectedItems.find(selected => selected.type === 'folder' && selected.clientFolderId === item.clientFolderId)}
                                        onChange={() => toggleSelectItem(item)}
                                        style={{ marginRight: 8 }}
                                    />
                                    <List.Item.Meta
                                        avatar={<a onClick={() => enterFolder(item)}><FolderOutlined style={{ fontSize: '36px' }} /></a>}
                                        title={<a onClick={() => enterFolder(item)}>{item.folderPath}</a>}
                                        description={<><UserOutlined style={{ fontSize: 14, marginRight: 4 }} /> {item.createdByName}</>}
                                    />
                                </List.Item>
                            );
                        }
                        const ext = getFileExtension(item.fileName);
                        return (
                            <List.Item actions={[
                                <Popconfirm title="Are you sure you want to delete this file?" 
                                    onConfirm={() => deleteFile(item.clientFileId)}
                                    okText="Yes" cancelText="No">
                                    <Button type="link" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            ]}>
                                <Checkbox
                                    checked={!!selectedItems.find(selected => selected.type === 'file' && selected.clientFileId === item.clientFileId)}
                                    onChange={() => toggleSelectItem(item)}
                                    style={{ marginRight: 8 }}
                                />
                                <List.Item.Meta
                                    avatar={
                                        <a onClick={(e) => { e.preventDefault(); handleFileOpen(item.fileUrl); }}
                                            href="#" style={{ marginRight: 8, width: '30px', height: '30px', display: 'flex',
                                            alignItems: 'center', justifyContent: 'center' }}>
                                            <FileIcon extension={ext} {...defaultStyles[ext] || {}} style={{ width: '20px', height: '30px' }} />
                                        </a>
                                    }
                                    title={<a onClick={(e) => { e.preventDefault(); handleFileOpen(item.fileUrl); }} href="#">
                                        {item.fileName}</a>}
                                    description={<><UserOutlined style={{ fontSize: 14, marginRight: 4 }} /> {item.createdByName} &mdash; {formatFileSize(item.fileSize)}</>}
                                />
                            </List.Item>
                        );
                    }}
                />
            </Card>

            {/* Bulk Move Modal */}
            <Modal
                title="Move Selected Items"
                open={bulkMoveModalVisible}
                onOk={handleBulkMove}
                onCancel={() => setBulkMoveModalVisible(false)}
            >
                <Tree
                    showIcon
                    treeData={treeData}
                    onSelect={(selectedKeys) => {
                        const key = selectedKeys[0];
                        setNewParentId(key === "root" ? null : parseInt(key));
                    }}
                    selectedKeys={newParentId === null ? ["root"] : [newParentId.toString()]}
                    style={{ height: 400, overflowY: 'auto' }}
                    size="large"
                />
            </Modal>

            {/* Folder Modal */}
            <Modal
                title={folderModalMode === 'create' ? "Create Folder" : "Update Folder"}
                open={folderModalVisible}
                onOk={handleFolderModalSubmit}
                onCancel={() => setFolderModalVisible(false)}
            >
                <Form form={folderForm} layout="vertical">
                    <Form.Item
                        label="Folder Name"
                        name="folderPath"
                        rules={[{ required: true, message: 'Please input the folder name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Permission Level"
                        name="permissionLevel"
                        rules={[{ required: true, message: 'Please select the permission level' }]}
                        initialValue="0"
                    >
                        <Select>
                            <Option value="0">0 - Public</Option>
                            <Option value="1">1 - Price Reviewers & Admins</Option>
                            <Option value="2">2 - Admins</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ClientFiles;
