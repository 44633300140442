import React, { useState, useEffect } from 'react';
import { List, Avatar, Card, Drawer, Typography, Divider, Button, Empty, Col, Row, Tabs, Tooltip, message, Badge } from 'antd';
import {
  MailOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import config from './Config';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const TaskPage = ({ isDarkTheme }) => { // modified to accept isDarkTheme
  const [receivedTasks, setReceivedTasks] = useState([]);
  const [sentTasks, setSentTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');

  useEffect(() => {
    fetchReceivedTasks();
    fetchSentTasks();
  }, []);

  const fetchReceivedTasks = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/usertask/1`); // Replace with your API endpoint
      const tasks = response.data;

      const received = tasks.filter(task => task.userId === 1); // Replace 1 with actual user ID logic
      setReceivedTasks(received);
    } catch (error) {
      message.error('Failed to fetch received tasks');
      console.error('Error fetching received tasks:', error);
    }
  };

  const fetchSentTasks = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/usertask/1/sent`); // New endpoint for sent tasks
      setSentTasks(response.data);
    } catch (error) {
      message.error('Failed to fetch sent tasks');
      console.error('Error fetching sent tasks:', error);
    }
  };

  const fetchTaskDetails = async (taskId) => {
    try {
      const response = await axios.get(`${config.apiUrl}/usertask/task/${taskId}`); // Replace with your task detail endpoint
      setSelectedTask(response.data);
      fetchReceivedTasks(); // Update received tasks after fetching details
      if (window.innerWidth < 768) {
        setDrawerVisible(true);
      }
    } catch (error) {
      message.error('Failed to fetch task details');
      console.error('Error fetching task details:', error);
    }
  };

  const handleTaskClick = (task) => {
    if (activeTabKey === '1') {
      // Fetch task details for received tasks only
      fetchTaskDetails(task.taskId);
    } else {
      // Use task data directly for sent tasks
      setSelectedTask(task);
      if (window.innerWidth < 768) {
        setDrawerVisible(true);
      }
    }
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const renderTaskList = (tasks) => (
    <Row gutter={8} wrap={false}>
      <Col xs={24} md={12}>
        <List
          itemLayout="horizontal"
          dataSource={tasks}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
            size: 'small',
          }}
          renderItem={(task) => {
            const backgroundColor = isDarkTheme 
              ? (task.isOpened ? "#2a2a2a" : "#3a3a3a") // changed value for unread tasks in dark theme
              : (task.isOpened ? "#f0f0f0" : "white");
            const borderLeft = task.isOpened 
              ? '3px solid #D18266'
              : '3px solid transparent';
            return (
              <List.Item
                style={{
                  cursor: 'pointer',
                  backgroundColor,
                  marginBottom: 5,
                  padding: '8px',
                  borderLeft
                }}
                onClick={() => handleTaskClick(task)}
              >
                <List.Item.Meta
                  avatar={
                    task.isOpened 
                      ? <Avatar icon={<MailOutlined />} />
                      : <Badge count="New" size='small' >
                          <Avatar icon={<MailOutlined />} />
                        </Badge>
                  }
                  title={
                    <Text
                      strong={!task.isOpened}
                      style={{
                        color: isDarkTheme && !task.isOpened ? 'white' : task.isOpened ? 'gray' : 'black'
                      }}
                    >
                      {task.createdByName}
                    </Text>
                  }
                  description={
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Text>{task.subject}</Text>
                      <br />
                      <Text type="secondary">{formatDate(task.createdAt)}</Text>
                    </div>
                  }
                  style={{ paddingLeft: 8 }}
                />
              </List.Item>
            );
          }}
        />
      </Col>
      <Col xs={0} md={12}>
        {selectedTask ? (
          <Card>
            <Title level={4}>{selectedTask.subject}</Title>
            <Divider />
            <Text strong>Creator: </Text>
            <Text>{selectedTask.createdByName}</Text>
            <Divider />
            <Text>{selectedTask.content}</Text>
            <Divider />
            <Text strong>Status: </Text>
            {selectedTask.status === 'Completed' ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: 'orange' }} />
            )}
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button 
                type="default" 
                onClick={() => window.location.href = selectedTask.actionUrl} 
                style={{ marginRight: '8px' }}
              >
                Open
              </Button>
            </div>
          </Card>
        ) : (
          <Card>
            <Empty description="Select a task to view details" />
          </Card>
        )}
      </Col>
    </Row>
  );

  return (
    <div>
      <div style={{ padding: '10px' }}>
        <Title level={3}>
          <CommentOutlined /> My Tasks
        </Title>
      </div>
      <Tabs defaultActiveKey="1" style={{ margin: 0 }} onChange={setActiveTabKey}>
        <TabPane tab="Received Tasks" key="1">
          {renderTaskList(receivedTasks)}
        </TabPane>
        <TabPane tab="Sent Tasks" key="2">
          {renderTaskList(sentTasks)}
        </TabPane>
      </Tabs>

      <Drawer
        title={selectedTask?.subject}
        placement="right"
        closable={true}
        onClose={handleCloseDrawer}
        open={drawerVisible}
        width={360}
      >
        {selectedTask && (
          <div>
            <Text strong>Creator: </Text>
            <Text>{selectedTask.createdByName}</Text>
            <Divider />
            <Text strong>Content: </Text>
            <Text>{selectedTask.content}</Text>
            <Divider />
            <Text strong>Status: </Text>
            {selectedTask.status === 'Completed' ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: 'orange' }} />
            )}
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Tooltip title="Open">
                <Button 
                  type="default" 
                  onClick={() => window.location.href = selectedTask.actionUrl} 
                  style={{ marginRight: '8px' }} 
                >
                  Open
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default TaskPage;
