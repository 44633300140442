// src/ClientTable.js
import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Popconfirm, message, Typography, Divider, Input, Space, Avatar } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './Config';

const { Title, Text } = Typography;

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/clients`);
      setClients(response.data);
    } catch (error) {
      console.error("There was an error fetching the clients!", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (client) => {
    navigate(client ? `/clients/edit/${client.clientId}` : '/clients/new');
  };

  const handleDelete = async (clientId) => {
    setLoading(true);
    try {
      await axios.delete(`${config.apiUrl}/clients/${clientId}`);
      message.success('Client deleted successfully');
      fetchClients(); // Refresh the list after deletion
    } catch (error) {
      console.error("There was an error deleting the client!", error);
      message.error('Failed to delete client');
    } finally {
      setLoading(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    fetchClients(); // Reset to fetch all clients
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...getColumnSearchProps('clientName'),
      render: (text) => (
        <>
          <Avatar>{text[0]}</Avatar>
          <Text strong style={{ paddingLeft: 8 }}>{text}</Text>
        </>
      ),
    },
    {
      title: 'Legal ID & Type',
      key: 'legalInfo',
      responsive: ['md'],
      // Optionally enable search only on legalId if needed:
      ...getColumnSearchProps('legalId'),
      render: (_, record) => (
        <>
          <div>{record.legalId}</div>
          <div>{record.clientType === 'business' ? 'Business' : record.clientType === 'person' ? 'Person' : record.clientType}</div>
        </>
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      responsive: ['md'],
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size={"middle"}>
          <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} style={{ marginRight: 8 }} />
          <Popconfirm
            title="Are you sure you want to delete this client?"
            onConfirm={() => handleDelete(record.clientId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title level={2}>Client Management</Title>
      <Text>
        Use this page to manage the Clients. You can add, edit, and delete clients.
      </Text>
      <Divider />
      <Button type="primary" onClick={() => handleEdit(null)} style={{ marginBottom: 16 }}>
        Create Client
      </Button>
      <Table
        dataSource={clients}
        columns={columns}
        rowKey="clientId"
        loading={loading}
      />
    </>
  );
};

export default ClientList;
