// ProjectSummary.jsx

import React, { useEffect, useState, useCallback } from 'react';
import { Descriptions, Button, Spin, message, Typography, Divider, Row, Col, Card, Tabs } from 'antd';
import dayjs from 'dayjs';
import { EditOutlined, LoadingOutlined, ReconciliationOutlined, ClockCircleOutlined, CheckCircleOutlined, FileSearchOutlined, FileTextOutlined, /*FileOutlined,*/ DollarOutlined, PaperClipOutlined } from '@ant-design/icons'; // Added FileSearchOutlined, FileTextOutlined, FileOutlined, DollarOutlined
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './Config';
import ProjectExchangeRate from './ProjectExchangeRate';
import ProjectEstimations from './ProjectEstimations';
import ProjectFiles from './ProjectFiles';

const { Title } = Typography;
const { TabPane } = Tabs;

const ProjectSummary = () => {
  const { projectId } = useParams(); // Extract projectId from URL
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exchangeRates, setExchangeRates] = useState([]);
  const [estimations, setEstimations] = useState([]);

  const baseURL = `${config.apiUrl}`; // Base URL for API endpoints

  // Fetch project details
  const fetchProjectDetails = useCallback(async () => {
    if (!projectId) {
      message.error('No project ID provided.');
      return;
    }

    setLoading(true);
    try {
      const [projectResponse, estimationsResponse] = await Promise.all([
        axios.get(`${baseURL}/projects/${projectId}`),
        axios.get(`${baseURL}/ProjectEstimations?projectId=${projectId}`)
      ]);

      setProject(projectResponse.data);
      setExchangeRates(projectResponse.data.projectExchangeRates || []);
      setEstimations(estimationsResponse.data || []); // Set estimations
    } catch (error) {
      console.error('Error fetching project details:', error);
      if (error.response && error.response.status === 404) {
        message.error('Project not found.');
        navigate('/home'); // Redirect to home or projects list
      } else {
        message.error('Failed to load project details.');
      }
    } finally {
      setLoading(false);
    }
  }, [baseURL, projectId, navigate]);

  useEffect(() => {
    fetchProjectDetails();
  }, [fetchProjectDetails]);

  // Handle Edit Button Click
  const handleEdit = () => {
    navigate(`/projects/${projectId}/edit`);
  };

  // Handle Review Prices Button Click
  const handleReviewPrices = () => {
    navigate(`/projects/${projectId}/pricereview`);
  };

  // Render loading state
  if (loading || !project) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} tip="Loading Project..." fullscreen />
      </div>
    );
  }

  // Format dates
  const formatDate = (dateString) => dayjs(dateString).format('YYYY-MM-DD');

  return (
    <div style={{ margin: '0 auto', padding: '8px' }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
        <Title level={2}><ReconciliationOutlined /> {project.projectName}</Title>
        <Button type="primary" icon={<EditOutlined />} onClick={handleEdit}>
          Edit
        </Button>
      </Row>

      <Divider />
        <Descriptions
            column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
            size="small"
            layout="horizontal"
            bordered
        >
            <Descriptions.Item label="Description">{project.description || '-'}</Descriptions.Item>
            <Descriptions.Item label="Client">{project.clientName || '-'}</Descriptions.Item>
            <Descriptions.Item label="Start Date">{formatDate(project.startDate)}</Descriptions.Item>
            <Descriptions.Item label="End Date">{formatDate(project.endDate)}</Descriptions.Item>
            <Descriptions.Item label="Area (m²)">{project.totalArea !== undefined ? project.totalArea : '-'}</Descriptions.Item>
            <Descriptions.Item label="Currency">
            {project.currencyCode && project.currencySymbol
                ? `${project.currencyCode} (${project.currencySymbol})`
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Tax Free">{project.isTaxFree ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Address">
            {project.locationAddress || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Reviewer">
            {project.priceReviewerName}  {project.isReviewed ? <CheckCircleOutlined  style={{ color: 'green' }}/> : <ClockCircleOutlined  />}
            </Descriptions.Item>
            <Descriptions.Item label="Approver">
            {project.approverName} {project.isApproved ? <CheckCircleOutlined  style={{ color: 'green' }}/> : <ClockCircleOutlined />}
            </Descriptions.Item>
            <Descriptions.Item label="Price List">{project.priceListName|| '-'}</Descriptions.Item>
            <Descriptions.Item label="Last Modified">
            {formatDate(project.lastModified)}
            </Descriptions.Item>
        </Descriptions>

        {/* New Buttons */}
        <Row justify="start" style={{ marginTop: '16px', gap: '8px' }}>
          <Button icon={<FileSearchOutlined />} onClick={handleReviewPrices}>
            Review Prices
          </Button>
          {/* ...other buttons to be added later... */}
        </Row>

        <Divider />
      
      <Tabs defaultActiveKey="estimations">
        <TabPane 
          key="estimations"
          tab={<span><FileTextOutlined /> Estimations</span>}
        >
          <ProjectEstimations estimations={estimations} projectId={projectId} fetchProjectDetails={fetchProjectDetails} />
        </TabPane>
        <TabPane 
          key="files"
          tab={<span><PaperClipOutlined /> Files</span>} // Changed icon here
        >
          <ProjectFiles projectId={projectId} />
        </TabPane>
        <TabPane 
          key="exchangerates"
          tab={<span><DollarOutlined /> Exchanges</span>}
        >
          <ProjectExchangeRate projectId={projectId} exchangeRates={exchangeRates} />
        </TabPane>        
      </Tabs>
    </div>
  );
};

export default ProjectSummary;
